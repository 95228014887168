import './style.scss';

import React, { useEffect, useRef, useState } from 'react';
import { Badge, Col, Container, Image, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Formatter } from 'services';
import { Collection, CollectionItem } from 'types/collection';
import {
    BoardsQueryParameters,
    FilterOptionsParameters,
    FilterParams,
    FilterStruct
} from 'types/styling';

import { Filters, Loader } from '../../../components';
import { styling } from '../../../content.json';
import Empty from './Empty';

interface IBoards {
    loading: boolean;
    loadingFilters: () => void | undefined;
    boards: Collection[];
    clearBoards: () => void;
    loadBoards: (input: BoardsQueryParameters) => void;
    loadBoardsFilters: (fields: Record<string, string>) => void;
    filters: FilterStruct[];
    updateFilters: (filter: FilterParams, isSelected: boolean, callback: () => void) => void;
    selectedFilters: Record<string, FilterOptionsParameters[]>;
    addToCanvas: (item: CollectionItem) => void;
}

const Boards: React.FC<IBoards> = ({
    loading,
    loadingFilters,
    boards = [],
    clearBoards,
    loadBoards,
    loadBoardsFilters,
    filters,
    selectedFilters,
    updateFilters,
    addToCanvas
}) => {
    const [jump, setJump] = useState(false);
    const [subcategory, setSubcategory] = useState('look');
    const resultsRef = useRef<HTMLDivElement>(null);
    const [gridItems, setGridItems] = useState<Collection[]>([]);

    useEffect(() => {
        loadBoardsFilters({});
        clearBoards();
    }, []);

    useEffect(() => {
        fetchData({
            type: [{ key: subcategory, text: subcategory }]
        });
    }, [subcategory]);

    useEffect(() => {
        if (!loading && boards) setGridItems(boards);
    }, [boards]);

    const fetchData = (filters?: Record<string, FilterOptionsParameters[]>) => {
        const activeFilters = filters ? filters : selectedFilters;
        const params = Object.keys(activeFilters).reduce(
            (ac, a) =>
                activeFilters[a].length
                    ? {
                          ...ac,
                          [a]: activeFilters[a]
                              .map((selection: FilterOptionsParameters) => selection.key)
                              .join(',')
                      }
                    : ac,
            {}
        );
        loadBoards({
            ...params,
            from: gridItems.length > 0 ? gridItems.length : 1,
            type: subcategory
        });
    };

    const onFilterChange = (filter: FilterParams) => {
        const isSelected =
            !!selectedFilters[filter.key] &&
            selectedFilters[filter.key].findIndex((item: FilterParams | FilterOptionsParameters) =>
                !Array.isArray(filter.value) ? item.key === filter.value.key : false
            ) > -1;
        updateFilters(filter, isSelected, fetchData);
    };

    return (
        <Container className="styling-room-boards" fluid>
            <Row>
                <Col className="filters-column">
                    {loadingFilters == undefined && <Loader />}
                    <p className="total">
                        {styling.boards.replace('%count%', Formatter.number(gridItems.length))}
                    </p>
                    <Filters
                        className={
                            selectedFilters.category && !selectedFilters.category.length
                                ? 'categories-all'
                                : ''
                        }
                        filters={filters}
                        selected={selectedFilters}
                        setFilter={onFilterChange}
                        showSelection={true}
                    />
                </Col>

                <div id="results" className="results col" ref={resultsRef}>
                    {loading && gridItems && gridItems.length < 1 && <Loader />}
                    <div className="results-bar">
                        <div className="subcategories">
                            {styling.subcategories.boards.map((category) => (
                                <p
                                    key={category.value}
                                    className={`boards-${category.value} ${
                                        category.value === subcategory ? 'selected' : ''
                                    }`}
                                    onClick={() => setSubcategory(category.value)}
                                >
                                    {category.text}
                                </p>
                            ))}
                        </div>

                        <div className="filter-badges">
                            {Object.keys(selectedFilters).map((key) =>
                                selectedFilters[key].map((filter) => (
                                    <Badge variant="light" key={filter.key}>
                                        <div
                                            className="close"
                                            onClick={() => onFilterChange({ key, value: filter })}
                                        />
                                        {filter.text}
                                    </Badge>
                                ))
                            )}
                        </div>
                    </div>
                    {gridItems && gridItems.length ? (
                        <InfiniteScroll
                            dataLength={gridItems ? gridItems.length : 0}
                            onScroll={() =>
                                setJump(
                                    resultsRef.current ? resultsRef.current.scrollTop > 0 : false
                                )
                            }
                            next={fetchData}
                            scrollableTarget="results"
                            hasMore={true}
                            style={{ overflow: 'hidden' }}
                            loader={undefined}
                        >
                            {gridItems.map((board, i, array) =>
                                array.length - 1 === i ? (
                                    <>
                                        <div key={board.uuid} className="board">
                                            <Image
                                                src={board.picture}
                                                onClick={() =>
                                                    board.items.forEach((item) => addToCanvas(item))
                                                }
                                            />
                                        </div>
                                        <div
                                            key="loading"
                                            className="board loading"
                                            style={{
                                                visibility:
                                                    loading && gridItems && gridItems.length > 0
                                                        ? 'visible'
                                                        : 'hidden'
                                            }}
                                        >
                                            <Image src={styling.assets.loading_logo} />
                                        </div>
                                    </>
                                ) : (
                                    <div key={board.uuid} className="board">
                                        <Image
                                            src={board.picture}
                                            onClick={() =>
                                                board.items.forEach((item) => addToCanvas(item))
                                            }
                                        />
                                    </div>
                                )
                            )}
                        </InfiniteScroll>
                    ) : (
                        <Empty section="boards" />
                    )}
                </div>
            </Row>
            <div
                className={`top-btn ${jump ? 'show' : 'hide'}`}
                onClick={() => (resultsRef.current ? resultsRef.current.scrollTo(0, 0) : null)}
            >
                {styling.top}
            </div>
        </Container>
    );
};

export default Boards;
