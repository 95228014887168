import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { getFollowUpIndex } from 'services/utils/bookings-utils';
import { ButtonActionType, QueueType } from 'types/booking';

import { Actions } from '../actions';
import {
    ActionTypes,
    BookingState,
    FiltersCategory,
    SessionAttribute,
    SingleBookingStatus
} from './types';

const initialState: BookingState = {
    loading: false,
    stylist: null,
    bookings: [],
    totalBookings: 0,
    filters: {
        search: null,
        queue: undefined,
        filter: ['dueDate']
    },
    bookingStatus: {
        queues: [],
        filterCategories: [],
        inquiriesAndArchive: [],
        highestNonZeroPriority: 0,
        activeQueue: undefined
    },
    clientsForStylist: [],
    bookingMetadata: { plans: {}, actions: {}, session: {}, labels: {} },
    selectedBookingId: null,
    selectedFilters: {
        sortBy: [
            {
                key: 'dueDate',
                value: 'Due Date',
                count: 0,
                priority: 0
            }
        ]
    },
    unreadMessages: 0,
    bookingPaginate: {
        meta: {
            totalItems: 0,
            itemCount: 0,
            itemsPerPage: 0,
            totalPages: 0,
            currentPage: 1
        },
        links: {
            first: '',
            previous: null,
            next: null
        }
    },
    bookingLoader: true,
    savedCurrentPage: 1,
    error: null
};

const bookingReducer = (state = initialState, action: Actions): BookingState => {
    switch (action.type) {
        case ActionTypes.BOOK_STYLIST: {
            return {
                ...state,
                stylist: action.payload
            };
        }
        case ActionTypes.CLEAR_BOOKINGS: {
            return {
                ...state,
                stylist: null
            };
        }
        case ActionTypes.CLEAR_BOOKINGS_LIST: {
            return {
                ...state,
                bookings: initialState.bookings
            };
        }
        case ActionTypes.CLEAR_BOOKINGS_SELECTION: {
            return {
                ...state,
                bookings: initialState.bookings,
                bookingStatus: initialState.bookingStatus,
                selectedFilters: initialState.selectedFilters,
                savedCurrentPage: initialState.savedCurrentPage,
                filters: initialState.filters
            };
        }
        case ActionTypes.SET_TOTAL_BOOKINGS: {
            return {
                ...state,
                totalBookings: action.payload
            };
        }
        case ActionTypes.LOAD_BOOKINGS_SUCCESS: {
            return {
                ...state,
                bookings: action.payload
            };
        }
        case ActionTypes.SET_BOOKINGS: {
            return {
                ...state,
                bookings: action.payload
            };
        }
        case ActionTypes.SET_BOOKINGS_COUNT:
        case ActionTypes.LOAD_BOOKINGS_COUNT_SUCCESS:
            const statusIndex = action.payload.filterCategories.findIndex(
                (status) => status.key === 'pending'
            );
            const newMessages = action.payload.filterCategories[statusIndex].filters.find(
                (filter) => filter.key === QueueType.newMessages
            );
            const sortedFilterCategories = sortFiltersCategories(action.payload.filterCategories);
            action.payload.filterCategories = sortedFilterCategories;
            return {
                ...state,
                bookingStatus: action.payload,
                unreadMessages: newMessages ? newMessages.count : 0
            };
        case ActionTypes.LOAD_CLIENTS_FOR_STYLIST_SUCCESS: {
            return {
                ...state,
                clientsForStylist: action.payload
            };
        }
        case ActionTypes.SET_FILTER:
            const newFilters = {
                search: action.payload.search,
                queue: action.payload.queue ? action.payload.queue : state.filters.queue,
                filter: action.payload.filter ? action.payload.filter : state.filters.filter
            };
            return {
                ...state,
                filters: newFilters
            };
        case ActionTypes.UPDATE_UNREAD_MESSAGES:
            return {
                ...state,
                bookings: updateBookings(
                    state,
                    action.payload.session_id,
                    'hasUnreadMessages',
                    action.payload.isRead
                ),
                unreadMessages: (state.unreadMessages -= 1)
            };
        case ActionTypes.UPDATE_IMPORTANCE_LEVEL:
            return {
                ...state,
                bookings: updateBookings(
                    state,
                    action.payload.channel_id,
                    'isFlaggedImportant',
                    action.payload.isImportance
                )
            };
        case ActionTypes.UPDATE_SELECTED_BOOKING_ID:
            return {
                ...state,
                selectedBookingId: action.payload
            };
        case ActionTypes.UPDATE_SELECTED_FILTERS:
            return {
                ...state,
                selectedFilters: action.payload
            };
        case ActionTypes.SET_BOOKINGS_NETADATA:
            return {
                ...state,
                bookingMetadata: action.payload
            };
        case ActionTypes.SET_BOOKING_PAGINATE:
            return {
                ...state,
                bookingPaginate: action.payload
            };
        case ActionTypes.SET_LOADING:
            return {
                ...state,
                bookingLoader: action.payload
            };
        case ActionTypes.REFRESH_BOOKINGS:
            return {
                ...state,
                bookings: action.payload
            };
        case ActionTypes.SET_ACTIVE_QUEUE:
            return {
                ...state,
                bookingStatus: { ...state.bookingStatus, activeQueue: action.payload }
            };
        case ActionTypes.SAVE_CURRENT_PAGE:
            return {
                ...state,
                savedCurrentPage: action.payload
            };
        case ActionTypes.LOAD_BOOKINGS_COUNT_FAILURE:
        case ActionTypes.LOAD_BOOKINGS_FAILURE:
            return {
                ...state,
                bookings: [],
                bookingLoader: false,
                error: action.payload
            };
        case ActionTypes.SET_ERROR:
            return {
                ...state,
                error: null
            };
        case ActionTypes.UPDATE_SINGLE_BOOKING_STATUS:
            const singleBooking = action.payload.booking;
            switch (action.payload.status) {
                case SingleBookingStatus.followUp:
                    const followUpLabelIndex = getFollowUpIndex(singleBooking);
                    singleBooking.session.labels[followUpLabelIndex].text = 'Follow up sent';
                    return {
                        ...state,
                        bookings: state.bookings.map((booking) =>
                            booking.session.sid === singleBooking.session.sid
                                ? singleBooking
                                : booking
                        )
                    };
                case SingleBookingStatus.requestEndSession:
                    const hasLabel = singleBooking.session.labels.find((label) => label.type === 3);
                    if (!hasLabel) {
                        singleBooking.session.labels.push({ type: 3, text: 'End Session Sent' });

                        singleBooking.actions = singleBooking.actions.filter(
                            (action) => action.key !== ButtonActionType.requestEndSession
                        );
                    }
                    return {
                        ...state,
                        bookings: state.bookings.map((booking) =>
                            booking.session.sid === singleBooking.session.sid
                                ? singleBooking
                                : booking
                        )
                    };
                case SingleBookingStatus.inquiriesReply:
                    return {
                        ...state,
                        bookings: state.bookings.map((booking) =>
                            booking.session.sid === singleBooking.session.sid
                                ? singleBooking
                                : booking
                        )
                    };
                default:
                    return state;
            }
        default:
            return state;
    }
};

const updateBookings = (
    state: BookingState,
    session_id: string,
    attr: SessionAttribute,
    enable: boolean
) => {
    if (attr === 'isFlaggedImportant' && state.filters.queue !== QueueType.inquiries) {
        const manipulate = enable ? 1 : -1;
        const queues = state.bookingStatus.queues;
        const queueIndex = state.bookingStatus.queues.findIndex(
            (status) => status.key === QueueType.important
        );

        queues[queueIndex].count += manipulate;
        if (queues[queueIndex].count < 0) queues[queueIndex].count = 0;
    }

    if (attr === 'hasUnreadMessages') {
        const statusIndex = state.bookingStatus.filterCategories.findIndex(
            (status) => status.key === 'pending'
        );
        const unreadIndex = state.bookingStatus.filterCategories[statusIndex].filters.findIndex(
            (filter) => filter.key === QueueType.newMessages
        );

        state.bookingStatus.filterCategories[statusIndex].filters[unreadIndex].count -= 1;
    }

    return state.bookings.map((booking) => {
        if (booking.session.sid === session_id) booking.session[attr] = enable;
        return booking;
    });
};

const sortFiltersCategories = (filtersCategory: FiltersCategory[]) => {
    const sortedFiltersCategory = filtersCategory.map((filter) => {
        const sortedFilters = filter.filters.sort((a, b) => a.priority - b.priority);
        filter.filters = sortedFilters;
        return filter;
    });
    return sortedFiltersCategory;
};

export const name = 'booking';
export const useBookingStore: TypedUseSelectorHook<BookingState> = (selector, ...args) =>
    useSelector((store: any) => selector(store[name]), ...args);
export default bookingReducer;
