import { KeyValue } from './utils';

export enum BookSessionType {
    archived = 'archived',
    inquiry = 'inquiry'
}

export enum loyalType {
    bronze = 'bronze',
    gold = 'gold',
    platinum = 'platinum'
}

export interface FilterOption {
    key: string;
    count: number;
    priority?: number;
    text?: string;
    singleSelect?: boolean;
    value?: string;
}
export interface BookingFilter {
    key: string;
    value: FilterOption;
}

// new bookings type for session management
export interface BookingType {
    client: ClientInfo;
    session: Session;
    actions: ButtonActions[];
}

export interface ClientInfo {
    fullName: string;
    firstName: string;
    lastName: string;
    loyalty?: string;
    uuid: string;
}

export interface Session {
    type: string;
    sid: string;
    description: string;
    lastModified: Date;
    dueDate: Date;
    labels: Label[];
    isFlaggedImportant: boolean;
    hasUnreadMessages: boolean;
    hasSessionView: boolean;
    isPendingFollowup: boolean;
    plan?: number;
    pricingModel?: string;
    looksCount?: number;
    looksMax?: number;
    price?: number;
    uuid: string;
}

export interface Label {
    text: string;
    type: number;
}

interface PlanType {
    text: string;
    background: string;
}

export interface LabelType {
    color: string;
    background: string;
    deprecated?: boolean;
}

interface ActionType {
    value: string;
}

interface SessionType {
    type: string;
}
export interface BookingConfigResponse {
    session: Record<string, SessionType>;
    actions: Record<string, ActionType>;
    plans: Record<string, PlanType>;
    labels: Record<string, LabelType>;
}

export interface ButtonActions {
    key: ButtonActionType;
    type: number;
    priority: number;
    isCta?: boolean;
}

export interface ClientBooking {
    age: string;
    budget: number; //TODO: dont think it is a number -perhaps array of obj
    city: string;
    country: string;
    gender: string;
    level: string;
    locations: string;
    name: string;
    picture: string;
    uuid: string;
}
export interface Booking {
    brand: {
        name: string;
        uuid: string;
    };
    categories: string[];
    client: ClientBooking;
    created: string;
    current_session_status: string;
    current_session_status_new: string;
    client_data_visited: boolean;
    end_session_enabled: boolean;
    follow_up: boolean;
    goals: KeyValue[];
    is_archive: boolean;
    is_important: boolean;
    is_vip: boolean;
    revision_enabled: boolean;
    outfits_count: number;
    last_message: string;
    last_message_client: string;
    plan: string;
    price: string;
    pricing_model: string;
    request_uuid: string;
    revision_look_uuid: string;
    sid: string;
    status: string;
    status_description: string;
}

export enum QueueType {
    all = 'all',
    new = 'new',
    previous = 'previous',
    active = 'active',
    important = 'important',
    dueToday = 'dueToday',
    overdue = 'overdue',
    inquiries = 'inquiries',
    archived = 'archived',
    newMessages = 'unread'
}

export enum ButtonActionType {
    chat = 'chat',
    restyle = 'restyle',
    archive = 'archive',
    moodboard = 'moodboard',
    styleboard = 'styleboard',
    forceEndSession = 'forceEndSession',
    requestEndSession = 'requestEndSession'
}

export interface BookingQueue {
    key: QueueType;
    value: string;
    count: number;
    priority: number;
    selected?: boolean;
}
