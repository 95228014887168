import './style.scss';

import { PresistentCanvas } from 'components/Canvas';
import ClientInfo from 'containers/ClientInfo';
import { inbox } from 'content.json';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { isPartner } from 'services/utils/user-utils';
import { useRoomStore } from 'store/room/reducer';

import { Canvas, Page } from '../../components';
import { User } from '../../services';
import { RoomState } from '../../types/styling';
import Header from './Header';
import Sections from './Sections';

const StylingRoom = ({
    type,
    user,
    styleRequest = {},
    loadStyleRequest,
    updateStyleRequest,
    clearCanvas,
    toggleModal,
    updateCanvasDataUrl,
    canvasLayout,
    revision,
    setCanvasLayout,
    setCanvasSizeIndex,
    addToCanvas,
    updateGender,
    clearSelectedFilters,
    location
}) => {
    const history = useHistory();
    const { request_uuid, tab = 'shop' } = useParams();
    const [dressingRoom, setDressingRoom] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [section, setSection] = useState(<div />);
    const [client, setClient] = useState(null);
    const roomState = useRoomStore((store) => store.stylingType);
    const [isPresistentCanvas, setIsPresistentCanvas] = useState(false);
    const presistentCanvasFlag = useFlags().persistentCanvas;
    const isPartnerStylist = isPartner();
    useEffect(() => {
        const isDressingRoom = location.pathname.includes('dressing-room');
        if (!request_uuid && !isDressingRoom) history.push('/');
        if (!isDressingRoom) loadStyleRequest(request_uuid);
        setDressingRoom(isDressingRoom);
        setCanvasLayout('free');
        setCanvasSizeIndex(1);
        return () => {
            updateStyleRequest({});
            clearCanvas();
        };
    }, []);

    useEffect(() => {
        if (styleRequest.owner) fetchClientData(styleRequest.owner.user_uuid);
    }, [styleRequest]);

    useEffect(() => {
        if (styleRequest.uuid || (dressingRoom && user)) {
            updateGender(styleRequest.owner ? styleRequest.owner.gender : user.gender);
            setSection(Sections[tab]);
            if (dressingRoom && presistentCanvasFlag && user) setIsPresistentCanvas(true);
        }
    }, [tab, styleRequest, dressingRoom, user]);

    useEffect(() => {
        if (revision.items && type === RoomState.revision) {
            revision.items.forEach((item) => {
                if (!item.is_selected) addToCanvas(item);
            });
        }
    }, [revision, type]);

    const fetchClientData = async (uuid) => {
        const { data } = await User.get(uuid);
        setClient(data);
        if (presistentCanvasFlag && data) {
            setIsPresistentCanvas(true);
        }
    };

    const onMenuSelect = (selection) => {
        clearSelectedFilters();
        const url = dressingRoom
            ? `/dressing-room/${selection}`
            : `/styling-room/${request_uuid}/${selection}${location.search}`;
        history.push(url);
    };

    const getStyleRequestType = () => {
        if (dressingRoom) return RoomState.look;
        if (isPartnerStylist) return RoomState.look;
        return roomState === RoomState.moodboard ? RoomState.moodboard : RoomState.look;
    };

    const onSave = (dataUrl) => {
        const MODAL_CONTENT = {
            look: 'SaveStyleboard',
            revision: 'SaveStyleboard',
            moodboard: 'SaveMoodboard',
            personal: 'SavePersonalShopping'
        };
        const styleRequestType = getStyleRequestType();

        if (dataUrl) updateCanvasDataUrl(dataUrl);
        toggleModal({
            type:
                type && !dressingRoom
                    ? MODAL_CONTENT[canvasLayout === 'grid' ? RoomState.personal : type]
                    : !client && !styleRequest.length
                    ? MODAL_CONTENT[styleRequestType]
                    : MODAL_CONTENT[
                          canvasLayout === 'grid' ? RoomState.personal : styleRequestType
                      ],
            url: dressingRoom
                ? '/dressing-room/boards'
                : `/inbox?user_uuid=${
                      styleRequest.owner ? styleRequest.owner.user_uuid : user.user_uuid
                  }`
        });
    };

    return (
        <Page header={false} footer={false}>
            <Container className="styling-room" fluid>
                <Header
                    client={dressingRoom ? user : styleRequest.owner}
                    styleData={styleRequest.styleData}
                    onDetailsToggle={setShowDetails}
                    onMenuSelect={onMenuSelect}
                    active={tab}
                    dressingRoom={dressingRoom}
                    onBack={() => history.push('/inbox')}
                />

                <Row className="content">
                    <Col>{section}</Col>

                    {!dressingRoom && (
                        <Col className={`info ${showDetails ? 'show' : 'hide'}`}>
                            <p className="title">{inbox.bookings.details}</p>
                            <div className="close" onClick={() => setShowDetails(false)} />
                            {client && showDetails && (
                                <ClientInfo
                                    client={{ ...client, request_uuid: request_uuid }}
                                    stylist={styleRequest.stylist}
                                />
                            )}
                        </Col>
                    )}
                    {isPresistentCanvas && user ? (
                        <PresistentCanvas
                            canvasName={''.concat(user.user_uuid, client?.user_uuid ?? '')}
                            onSave={onSave}
                            type={dressingRoom ? 'personal' : type}
                            isMoodboard={roomState === 'moodboard'}
                        />
                    ) : (
                        <Canvas
                            onSave={onSave}
                            type={dressingRoom ? 'personal' : type}
                            isMoodboard={roomState === 'moodboard'}
                        />
                    )}
                </Row>
            </Container>
        </Page>
    );
};

export default withLDConsumer()(StylingRoom);
