import axios from 'axios';

import Config from './Config';

const Payments = {
    payInvoice: (data, model, token) =>
        axios.post(
            `${Config.get('apiroot2')}${model === 'onetime' ? 'charge' : 'subscription'}`,
            data,
            { headers: { token } }
        ),
    getStripeKey: () => process.env.REACT_APP_STRIPE,
    getCountries: (token) => axios.get(`${Config.get('apiroot2')}country`, { headers: { token } }),
    getStates: (country, token) =>
        axios.get(`${Config.get('apiroot2')}state`, { params: { country }, headers: { token } })
};

export default Payments;
