import './style.scss';

import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { registerProperties } from 'services/mixpanel';
import { sentryException } from 'services/SentryLogging';

import { Input, Loader, ProfilePhoto } from '../../../components';
import { settings as texts } from '../../../content';
import { useUserName } from '../../../hooks';
import { Validator } from '../../../services';
import Breadcrumbs from './Breadcrumbs';

const Info = ({ user, updateProfilePicture, updateGeneralData }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [info, setInfo] = useState({});
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [invalid, setInvalid] = useState(false);
    const { fullname } = useUserName(user);

    useEffect(() => {
        if (user) {
            setLoading(false);
            setInfo(user);
            setEmail(user.email);
        }
    }, [user]);

    useEffect(() => {
        setName(fullname);
    }, [fullname]);

    useEffect(() => {
        setInvalid(Validator.email(email) || Validator.name(name));
    }, [email, name]);

    const onSave = async (e) => {
        try {
            setLoading(true);
            if (e.target.files) {
                await updateProfilePicture(e.target.files, {
                    user_uuid: user.user_uuid,
                    token: user.token
                });
            } else {
                await updateGeneralData({
                    ...info,
                    email,
                    first_name: name.length ? name.split(' ')[0] : '',
                    last_name: name.length ? name.split(' ')[1] : ''
                });
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
            sentryException(e, "Cannot update user's peronal details");
            setError(true);
        }
    };

    return (
        <Container className="settings-info" fluid>
            {loading && <Loader />}
            <Breadcrumbs />
            <label className="section-title">{texts.info.title}</label>
            <Row>
                <Col className="header">
                    <div className="photo-btn">
                        <label htmlFor="photo-btn">
                            <ProfilePhoto user={info} />
                            <p>{texts.info.photo}</p>
                        </label>
                        <input
                            type="file"
                            id="photo-btn"
                            accept="image/x-png,image/gif,image/jpeg"
                            onChange={onSave}
                        />
                    </div>
                    <div>
                        <label>{texts.info.header.replace('%name%', info.first_name || '')}</label>
                        <p className="d-none d-md-block">{texts.info.description}</p>
                    </div>
                </Col>
            </Row>
            <Form>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>{texts.info.name}</Form.Label>
                            <Input
                                className={Validator.name(name) ? 'field-error' : ''}
                                type="text"
                                props={{ value: name }}
                                onChange={({ text }) => setName(text || '')}
                            />
                            {Validator.name(name) && (
                                <p className="field-error-message">{texts.info.errors.name}</p>
                            )}
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>{texts.info.email}</Form.Label>
                            <Input
                                className={Validator.email(email) ? 'field-error' : ''}
                                type="email"
                                user={info}
                                onChange={({ text }) => setEmail(text || '')}
                            />
                            {Validator.email(email) && (
                                <p className="field-error-message">{texts.info.errors.email}</p>
                            )}
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>{texts.info.phone}</Form.Label>
                            <Input
                                type="phone"
                                number={info.phone_number || ''}
                                onChange={({ text }) => setInfo({ ...info, phone_number: text })}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>{texts.info.birthday}</Form.Label>
                            <Input
                                type="date"
                                onChange={({ date }) => {
                                    registerProperties({ Birthdate: date });
                                    return setInfo({ ...info, birthday: date });
                                }}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>{texts.info.location}</Form.Label>
                            <Input
                                type="text"
                                props={{
                                    value: info.location
                                }}
                                onChange={({ text }) => setInfo({ ...info, location: text })}
                            />
                        </Form.Group>

                        <Form.Group className="gender">
                            <Form.Label>{texts.info.gender}</Form.Label>
                            <div className="options">
                                {texts.info.genders.map(({ key, value }) => (
                                    <Input
                                        type="checkbox"
                                        key={key}
                                        value={key}
                                        label={value}
                                        checked={key === info.gender}
                                        onChange={() => {
                                            registerProperties({ Gender: key });
                                            setInfo({ ...info, gender: key });
                                        }}
                                    />
                                ))}
                            </div>
                        </Form.Group>
                    </Col>
                </Form.Row>

                <Button variant="dark" className="save-button" disabled={invalid} onClick={onSave}>
                    {texts.info.save}
                </Button>
                {error && <p className="error">{texts.info.errors.save}</p>}
            </Form>
        </Container>
    );
};

export default Info;
