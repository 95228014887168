import {
    CHANGE_MODAL,
    CHOSEN_ITEM,
    CLEAR_CART_FILTER,
    CLEAR_PROFILE_SELECTED_FILTERS,
    CLEAR_USER_CLOSET,
    CLEAR_USER_PHOTOS,
    LOGIN_START,
    PAGE_LOADED,
    RESET_PASSWORD_SUCCESS,
    SET_CART_FILTER,
    SET_CART_FILTERS,
    SET_CURRENT_ITEM,
    SET_CURRENT_OUTFIT,
    START_FILTERS_LOADING,
    START_LOADING,
    STOP_LOADING,
    TOGGLE_BIRTHDAY_MESSAGE,
    TOGGLE_MODAL,
    UPDATE_BILLING_DETAILS,
    UPDATE_CLOSET_TAGS,
    UPDATE_ORDERS,
    UPDATE_PROFILE_FILTERS,
    UPDATE_PROFILE_SELECTED_FILTERS,
    UPDATE_USER_CLOSET,
    UPDATE_USER_LOOKS,
    UPDATE_USER_ORDERS,
    UPDATE_USER_PHOTOS
} from './actions';

const initialState = {
    showModal: false,
    modalType: null,
    loading: false,
    loadingFilters: false,
    resetSuccess: false,
    messagesCount: 0,
    user: null,
    error: null,
    redirectUrl: null,
    cartLoading: false,
    cart: null,
    cartFilter: {},
    cartFilters: [],
    activeCartFilter: '',
    messages: [],
    channel: null,
    birthdayMessage: false,
    billingDetails: {},
    filters: [],
    filtersType: null,
    selectedFilters: {},
    closet: null,
    photos: [],
    looks: null,
    orders: [],
    ordersTotal: 0,
    totalItems: 0,
    closetTags: [],
    chosenItem: null,
    currentOutfit: null,
    currentItem: null,
    nextPage: 1
};

export default function (state = initialState, action) {
    switch (action.type) {
        case START_LOADING: {
            return {
                ...state,
                loading: true
            };
        }
        case LOGIN_START: {
            return {
                ...state,
                loading: true,
                error: null
            };
        }
        case RESET_PASSWORD_SUCCESS: {
            return {
                ...state,
                loading: false,
                resetSuccess: true
            };
        }
        case TOGGLE_MODAL: {
            const { type = '', url = null, data = null } = action.payload;
            return {
                ...state,
                showModal: !state.showModal,
                loading: false,
                error: null,
                redirectUrl: url || state.redirectUrl,
                modalType: type ? type : state.modalType,
                modalData: data
            };
        }
        case CHANGE_MODAL: {
            const { type = '', data = {} } = action.payload;
            return {
                ...state,
                error: null,
                loading: false,
                modalType: type,
                modalData: data
            };
        }
        case SET_CART_FILTER: {
            const {
                filter: { key, value }
            } = action.payload;
            return {
                ...state,
                cart: null,
                activeCartFilter: key,
                cartFilter: value
            };
        }
        case SET_CART_FILTERS: {
            const { filters } = action.payload;
            return {
                ...state,
                cartFilters: filters
            };
        }
        case CLEAR_CART_FILTER: {
            return {
                ...state,
                activeCartFilter: '',
                cartFilter: {}
            };
        }
        case CHOSEN_ITEM: {
            return {
                ...state,
                chosenItem: { item: action.payload }
            };
        }
        case TOGGLE_BIRTHDAY_MESSAGE: {
            return {
                ...state,
                birthdayMessage: !state.birthdayMessage
            };
        }
        case UPDATE_BILLING_DETAILS: {
            const { billingDetails } = action.payload;
            return {
                ...state,
                billingDetails
            };
        }
        case UPDATE_ORDERS: {
            const { orders, total, nextPage } = action.payload;
            return {
                ...state,
                orders,
                ordersTotal: total,
                nextPage: nextPage,
                loading: false
            };
        }

        case CLEAR_USER_CLOSET: {
            return {
                ...state,
                closet: null,
                totalItems: 0
            };
        }

        case UPDATE_USER_CLOSET: {
            const { items, total } = action.payload;
            return {
                ...state,
                closet: items,
                totalItems: total,
                loading: false
            };
        }

        case UPDATE_PROFILE_FILTERS: {
            const { filters, type } = action.payload;
            return {
                ...state,
                filters,
                filtersType: type,
                loadingFilters: false
            };
        }

        case UPDATE_PROFILE_SELECTED_FILTERS: {
            const { selected } = action.payload;
            return {
                ...state,
                selectedFilters: selected
            };
        }

        case CLEAR_PROFILE_SELECTED_FILTERS: {
            return {
                ...state,
                selectedFilters: {}
            };
        }

        case UPDATE_USER_PHOTOS: {
            const { photos, total } = action.payload;
            return {
                ...state,
                photos,
                totalItems: total,
                loading: false
            };
        }

        case CLEAR_USER_PHOTOS: {
            return {
                ...state,
                photos: [],
                totalItems: 0
            };
        }

        case UPDATE_USER_LOOKS: {
            const { items, total } = action.payload;
            return {
                ...state,
                looks: items,
                totalItems: total,
                loading: false
            };
        }

        case UPDATE_USER_ORDERS: {
            const { items, total } = action.payload;
            return {
                ...state,
                orders: items,
                totalItems: total,
                loading: false
            };
        }

        case UPDATE_CLOSET_TAGS: {
            const { tags } = action.payload;
            return {
                ...state,
                closetTags: tags,
                totalItems: tags.length,
                loading: false
            };
        }
        case SET_CURRENT_OUTFIT: {
            const { outfit } = action.payload;
            return {
                ...state,
                currentOutfit: outfit
            };
        }
        case SET_CURRENT_ITEM: {
            const { item } = action.payload;
            return {
                ...state,
                currentItem: item
            };
        }

        case START_FILTERS_LOADING: {
            return {
                ...state,
                loadingFilters: true
            };
        }

        case STOP_LOADING: {
            const { error } = action.payload;
            return {
                ...state,
                error,
                loading: false
            };
        }
        case PAGE_LOADED: {
            return {
                ...state,
                loading: false
            };
        }

        default:
            return state;
    }
}
