import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    addToCanvas,
    clearBoards,
    clearCloset,
    clearSelectedFilters,
    clearShop,
    clearStore,
    loadBoards,
    loadBoardsFilters,
    loadClientCart,
    loadCloset,
    loadClosetFilters,
    loadInspiration,
    loadInspirationTags,
    loadOrdersFilters,
    loadShop,
    loadShopFilters,
    loadStore,
    loadStoreFilters,
    removeStoreItem,
    setGridSize,
    updateGender,
    updateStylingRoomFilters
} from '../../../redux/reducers/stylingRoom/actions';
import { favorite, loadStylistFavorites } from '../../../redux/reducers/stylists/actions';
import { loadCartFilters, loadOrders } from '../../../redux/reducers/users/actions';
import Boards from './Boards';
import Closet from './Closet';
import Inspiration from './Inspiration';
import Shop from './Shop';
import Store from './Store';

const mapStateToProps = ({
    users: { cartFilters, orders },
    user: { user },
    stylingRoom: {
        loading,
        loadingFilters,
        closet,
        shop,
        store,
        inspiration,
        boards,
        cart,
        filters,
        filtersType,
        selectedFilters,
        gender,
        gridSize,
        total,
        styleRequest,
        itemsAvailability,
        nextPage,
        canvasLayout
    },
    stylists: { favorites, favoritesTotal },
    style: { tags }
}) => ({
    loading,
    loadingFilters,
    user,
    closet,
    shop,
    store,
    inspiration,
    boards,
    tags,
    cart,
    orders,
    filters,
    filtersType,
    selectedFilters,
    cartFilters,
    favorites,
    favoritesTotal,
    gender,
    gridSize,
    total,
    styleRequest,
    itemsAvailability,
    nextPage,
    canvasLayout
});

const mapDispatchToProps = (dispatch) => ({
    loadCloset: (params) => dispatch(loadCloset(params)),
    loadShop: (params) => dispatch(loadShop(params)),
    loadStore: (params) => dispatch(loadStore(params)),
    loadInspiration: (params) => dispatch(loadInspiration(params)),
    loadBoards: (params) => dispatch(loadBoards(params)),
    loadShopFilters: (params) => dispatch(loadShopFilters(params)),
    loadClosetFilters: (params) => dispatch(loadClosetFilters(params)),
    loadStoreFilters: (params) => dispatch(loadStoreFilters(params)),
    loadInspirationTags: (params) => dispatch(loadInspirationTags(params)),
    loadBoardsFilters: (params) => dispatch(loadBoardsFilters(params)),
    loadCartFilters: (user_uuid, params) => dispatch(loadCartFilters(user_uuid, params)),
    loadOrdersFilters: (user_uuid, params) => dispatch(loadOrdersFilters(user_uuid, params)),
    updateFilters: (filter, remove, callback) =>
        dispatch(updateStylingRoomFilters(filter, remove, callback)),
    loadStylistFavorites: (params) => dispatch(loadStylistFavorites(params)),
    favorite: (item, attr) => dispatch(favorite(item, attr)),
    updateGender: (gender) => dispatch(updateGender(gender)),
    setGridSize: (size) => dispatch(setGridSize(size)),
    clearShop: () => dispatch(clearShop()),
    clearBoards: () => dispatch(clearBoards()),
    clearCloset: () => dispatch(clearCloset()),
    clearStore: () => dispatch(clearStore()),
    clearSelectedFilters: () => dispatch(clearSelectedFilters()),
    addToCanvas: (item, type) => dispatch(addToCanvas(item, type)),
    removeStoreItem: (item) => dispatch(removeStoreItem(item)),
    loadClientCart: (user_uuid, params) => dispatch(loadClientCart(user_uuid, params)),
    loadOrders: (params) => dispatch(loadOrders(params))
});

export default {
    shop: withRouter(connect(mapStateToProps, mapDispatchToProps)(Shop)),
    store: withRouter(connect(mapStateToProps, mapDispatchToProps)(Store)),
    closet: withRouter(connect(mapStateToProps, mapDispatchToProps)(Closet)),
    inspiration: withRouter(connect(mapStateToProps, mapDispatchToProps)(Inspiration)),
    boards: withRouter(connect(mapStateToProps, mapDispatchToProps)(Boards))
};
