import './style.scss';

import { profile as texts } from 'content.json';
import { useIsMobile, useUserName, useWithDispatch } from 'hooks';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { setGridSize } from 'store/profile/actions';
import { useProfileStore } from 'store/profile/reducer';
import { useUserStore } from 'store/user/reducer';

interface IHeader {
    onMenuSelect: (s: string) => void;
    active: string;
}

const Header: React.FC<IHeader> = ({ onMenuSelect, active }) => {
    const user = useUserStore((store) => store.user);
    const gridSize = useProfileStore((store) => store.gridSize);
    const { fullname } = useUserName(user);
    const isMobile = useIsMobile();
    const setGridSizeAction = useWithDispatch(setGridSize);

    const getActiveState = (tab: string) => (tab === active ? 'active' : '');

    const onMenuClick = (section: string) => {
        if (active !== section) onMenuSelect(section);
    };

    const showGridSize = () => active !== 'cart' && active !== 'orders';

    return (
        <Row className="header">
            {user && (
                <Col className="user">
                    <div className="user-name">
                        <div className="name">{fullname}</div>
                        <div className={`level ${user?.level}`} />
                    </div>
                    <Link to="/settings" className="settings-btn" />
                </Col>
            )}
            <Col className="menu">
                {texts.header.map(({ key, text, order }) => (
                    <div
                        key={key}
                        className={`header-menu-item ${key} ${getActiveState(key)}`}
                        style={{
                            display: isMobile && !order.mobile ? 'none' : 'flex',
                            order: isMobile ? order.mobile : order.desktop
                        }}
                        onClick={() => onMenuClick(key)}
                    >
                        <p>{text}</p>
                    </div>
                ))}
            </Col>
            <Col>
                {showGridSize() && (
                    <div className="grid-size d-none d-md-flex">
                        <div
                            className={`size-btn large ${gridSize === 'large' ? 'active' : ''}`}
                            onClick={() => setGridSizeAction('large')}
                        />
                        <div
                            className={`size-btn small ${gridSize === 'small' ? 'active' : ''}`}
                            onClick={() => setGridSizeAction('small')}
                        />
                    </div>
                )}
            </Col>
        </Row>
    );
};

export default Header;
