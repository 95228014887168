import {
    CLEAR_RESULT,
    LOAD_COUNTRIES_FAILED,
    LOAD_COUNTRIES_SUCCESS,
    LOAD_STATES_FAILED,
    LOAD_STATES_SUCCESS,
    PAYMENT_BUTTON_DISABLED,
    PAYMENT_FAILED,
    PAYMENT_START,
    PAYMENT_SUCCESS,
    UPDATE_PAYMENT_TOKEN
} from './actions';

const initialState = {
    model: 'onetime',
    price: 0,
    coupon: {},
    cards: [],
    error: null,
    result: null,
    loading: false,
    paymentToken: '',
    buttonDisabled: true,
    countries: [],
    states: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case PAYMENT_START: {
            return {
                ...state,
                loading: true,
                error: null,
                result: null
            };
        }
        case PAYMENT_SUCCESS: {
            const { result } = action.payload;
            return {
                ...state,
                loading: false,
                error: null,
                paymentToken: result.paymentToken || '',
                result
            };
        }
        case PAYMENT_FAILED: {
            const { error } = action.payload;
            return {
                ...state,
                loading: false,
                paymentToken: '',
                result: null,
                error: {
                    ...state.error,
                    payment: error.response
                        ? error.response.data.error || error.response.data.message
                        : error
                }
            };
        }
        case CLEAR_RESULT: {
            return {
                ...state,
                result: null
            };
        }
        case UPDATE_PAYMENT_TOKEN: {
            const { token } = action.payload;
            return {
                ...state,
                paymentToken: token
            };
        }
        case PAYMENT_BUTTON_DISABLED: {
            const { buttonDisabled } = action.payload;
            return {
                ...state,
                buttonDisabled
            };
        }
        case LOAD_COUNTRIES_SUCCESS: {
            const { countries } = action.payload;
            return {
                ...state,
                countries
            };
        }
        case LOAD_STATES_SUCCESS: {
            const { states } = action.payload;
            return {
                ...state,
                states
            };
        }
        case LOAD_COUNTRIES_FAILED:
        case LOAD_STATES_FAILED: {
            const { error } = action.payload;
            return {
                ...state,
                error
            };
        }
        default:
            return state;
    }
}
