import './style.scss';

import { Image } from 'react-bootstrap';

import { useUserName } from '../../hooks';

const ProfilePhoto = ({ user, level = false }) => {
    const { initials } = useUserName(user);
    return (
        <div className={`profile-photo ${level ? user?.level : ''}`}>
            {(user?.picture_small || user?.profile_picture) && (
                <Image src={user.picture_small || user.profile_picture} />
            )}
            {initials}
        </div>
    );
};

export default ProfilePhoto;
