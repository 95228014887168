import { store } from 'store';
import { Client } from 'types/user';

const getUser = () => store.getState().user?.user;

const fallbakcStylists: { [index: string]: Record<string, string> } = {
    female: {
        Mika: '1e7c0536-5458-11e4-b',
        Daphne: '1e7c723d-5458-11e4-b',
        Sophia: 'fb86adfd-4206-11ea-b'
    },
    male: {
        Alan: '46e2c71c-2c2a-11e6-b',
        Tara: '1e7c723d-5458-11e4-b',
        Meredith: '44f70dd9-c9f4-11eb-b'
    },
    nonbinary: {
        Mika: '1e7c0536-5458-11e4-b',
        Alan: '46e2c71c-2c2a-11e6-b',
        Daphne: '1e7c723d-5458-11e4-b'
    }
};

export const defaultStylists = (gender: string) => {
    if (gender) return Object.values(fallbakcStylists[gender]);
    else return Object.values(fallbakcStylists['female']);
};

export const extractUserData = (user: Client) => {
    if (user?.user_uuid && user?.email && user?.first_name && user?.last_name)
        return {
            key: user.user_uuid,
            email: user.email,
            name: ''.concat(user.first_name, ' ', user.last_name)
        };
    else return null;
};

export const isPartner = () => {
    const user = getUser();
    if (user?.partner?.id) return true;
    return false;
};
