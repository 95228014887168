import {
    CLEAR_FILTERS,
    CLEAR_OUTFIT,
    LOAD_FAILED,
    LOAD_ITEM_START,
    LOAD_OUTFIT_START,
    LOAD_START,
    LOAD_STYLISTS_SUCCESS,
    SET_FILTER_RESULTS_COUNT,
    TOGGLE_FILTERS,
    UPDATE_EVENTS,
    UPDATE_ITEM,
    UPDATE_OUTFIT,
    UPDATE_STYLE_SELECTED_FILTERS,
    UPDATE_TAGS
} from './actions';

const initialState = {
    loading: false,
    showFilters: false,
    stylists: [],
    filters: [],
    selectedFilters: {},
    count: 0,
    error: null,
    outfit: {},
    item: {},
    tags: [],
    events: [],
    nextPage: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOAD_START: {
            return {
                ...state,
                matches: [],
                loading: true,
                loadingForModal: true
            };
        }
        case LOAD_STYLISTS_SUCCESS: {
            return {
                ...state,
                stylists: action.payload,
                loading: false
            };
        }
        case TOGGLE_FILTERS: {
            return {
                ...state,
                showFilters: !state.showFilters
            };
        }
        case UPDATE_STYLE_SELECTED_FILTERS: {
            const { selectedFilters } = action.payload;

            return {
                ...state,
                selectedFilters
            };
        }
        case SET_FILTER_RESULTS_COUNT: {
            const { count } = action.payload;

            return {
                ...state,
                count
            };
        }
        case CLEAR_FILTERS: {
            return {
                ...state,
                selectedFilters: {}
            };
        }
        case LOAD_OUTFIT_START: {
            return {
                ...state,
                outfit: {},
                loading: true
            };
        }
        case UPDATE_OUTFIT: {
            const { outfit } = action.payload;
            return {
                ...state,
                outfit,
                loading: false
            };
        }
        case CLEAR_OUTFIT: {
            return {
                ...state,
                outfit: {}
            };
        }
        case LOAD_ITEM_START: {
            return {
                ...state,
                item: {},
                loading: true
            };
        }
        case UPDATE_ITEM: {
            const { item } = action.payload;
            return {
                ...state,
                item,
                loading: false
            };
        }

        case UPDATE_TAGS: {
            const { type, tags } = action.payload;
            return {
                ...state,
                tags: {
                    ...state.tags,
                    [type]: tags
                },
                loading: false
            };
        }

        case UPDATE_EVENTS: {
            const { events } = action.payload;
            return {
                ...state,
                events,
                loading: false
            };
        }

        case LOAD_FAILED: {
            const { error } = action.payload;
            return {
                ...state,
                error,
                loading: false
            };
        }
        default:
            return state;
    }
}
