import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toggleModal } from 'store/ui/actions';

import {
    addToCanvas,
    clearCanvas,
    clearSelectedFilters,
    loadStyleRequest,
    setCanvasLayout,
    setCanvasSizeIndex,
    updateCanvasDataUrl,
    updateGender,
    updateStyleRequest
} from '../../redux/reducers/stylingRoom/actions';
import StylingRoom from './StylingRoom';

const mapStateToProps = ({
    user: { user },
    stylingRoom: { loading, loadingFilters, styleRequest, canvasLayout, revision, type }
}) => ({
    user,
    loading,
    loadingFilters,
    styleRequest,
    canvasLayout,
    revision,
    type
});

const mapDispatchToProps = (dispatch) => ({
    clearCanvas: () => dispatch(clearCanvas()),
    loadStyleRequest: (uuid) => dispatch(loadStyleRequest(uuid)),
    updateStyleRequest: (request) => dispatch(updateStyleRequest(request)),
    toggleModal: (data) => toggleModal(dispatch, data),
    updateCanvasDataUrl: (dataUrl) => dispatch(updateCanvasDataUrl(dataUrl)),
    addToCanvas: (item) => dispatch(addToCanvas(item)),
    updateGender: (gender) => dispatch(updateGender(gender)),
    clearSelectedFilters: () => dispatch(clearSelectedFilters()),
    setCanvasLayout: (layout) => dispatch(setCanvasLayout(layout)),
    setCanvasSizeIndex: (size) => dispatch(setCanvasSizeIndex(size))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StylingRoom));
