import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toggleModal } from 'store/ui/actions';

import { loadTags } from '../../../redux/reducers/style/actions';
import { saveMoodboard, stylingRoomClearResult } from '../../../redux/reducers/stylingRoom/actions';
import SaveMoodboard from './SaveMoodboard';

const mapStateToProps = ({
    ui: { redirectUrl },
    style: { loading, tags },
    stylingRoom: { loading: stylingRoomLoading, result, gender, styleRequest }
}) => ({
    loading,
    stylingRoomLoading,
    styleRequest,
    tags,
    result,
    gender,
    redirectUrl
});

const mapDispatchToProps = (dispatch) => ({
    loadTags: (gender, type) => dispatch(loadTags(gender, type)),
    saveMoodboard: (info) => dispatch(saveMoodboard(info)),
    clearResult: () => dispatch(stylingRoomClearResult()),
    toggleModal: (data) => toggleModal(dispatch, data)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SaveMoodboard));
