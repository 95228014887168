import './style.scss';

import { fabric } from 'fabric';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import { useWithDispatch } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Image as BootstrapImage } from 'react-bootstrap';
import { setRoomStylingType } from 'store/room/actions';
import { useRoomStore } from 'store/room/reducer';

import { styling } from '../../content.json';
import * as canvasUtils from '../../services/utils/canvas-utils';
import * as canvasTypes from '../../types/canvas';
import { Loader } from '../';
import { CanvasGrid } from '.';
import CropModal from './CanvasCropModal';
import CanvasToolbar from './CanvasToolbar';
import GridIcon from './utils/GridIcon';

export const PersistentCanvas = ({
    canvasSizeIndex,
    canvasSizesArray,
    setCanvasSizeIndex,
    canvasLayout,
    setCanvasLayout,
    items = [],
    ps = [],
    type,
    removeFromCanvas,
    setCanvasLoad,
    onSave,
    canvasName,
    canvasLoading,
    updateCanvasTotalPrice,
    psTotalPrice,
    itemsTotalPrice,
    addToCanvas
}: canvasTypes.IPersistentCanvas) => {
    const { editor, onReady } = useFabricJSEditor();
    const [canvasItems, setCanvasItems] = useState<string[]>([]);
    const [counter, setCounter] = useState({
        items: 0,
        ps: 0
    });
    const [toolbarSettings, setToolbarSettings] = useState<canvasTypes.IToolBar>({ show: false });
    const [cropModal, setCropModal] = useState<canvasTypes.ICrop>({ show: false });
    const [dimension, setDimension] = useState(0);
    const [preview, setPreview] = useState('');
    const [canvas, setCanvas] = useState<canvasTypes.ICanvas>();
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [isMoodboard, setIsMoodBoard] = useState(false);
    const setRoomStylingTypeAction = useWithDispatch(setRoomStylingType);
    const stylingRoomState = useRoomStore((store) => store.stylingType);

    const retriveItems = (canvasItems: canvasTypes.ICanvasItem[]) =>
        new Promise<void[]>((resolve) => {
            resolve(canvasItems.map((item) => addToCanvas(item, canvasLayout)));
        });

    const arrangeItemsData = async () => {
        const restoredCanvasItems = canvasUtils.retrieveCanvasData(canvasName, 'freeItems');
        if (restoredCanvasItems) {
            setLoadingData(true);
            const canvasItemsObject = JSON.parse(restoredCanvasItems);
            setCanvasItems(
                canvasItemsObject.reduce((acc: string[], item: canvasTypes.ICanvasItem) => {
                    acc.push(item.id);
                    return acc;
                }, [])
            );
            await retriveItems(canvasItemsObject);
            setLoadingData(false);
            updateCanvasTotalPrice();
        }
    };
    useEffect(() => {
        if (editor && !canvas) {
            const canvasObj = canvasUtils.initializeCanvas(
                editor,
                canvasSizesArray,
                canvasName,
                'free',
                setToolbarSettings,
                setCanvasLoad,
                updateCanvasTotalPrice,
                stylingRoomState
            ) as canvasTypes.ICanvas;
            if (!canvasObj.boardType && stylingRoomState) {
                canvasObj.boardType = stylingRoomState;
            }
            setIsMoodBoard(stylingRoomState === 'moodboard');
            arrangeItemsData();
            setCanvas(canvasObj);
        }
    }, [editor]);

    useEffect(() => {
        setIsMoodBoard(stylingRoomState === 'moodboard');
    }, [stylingRoomState]);

    useEffect(() => {
        if (canvas) {
            canvasUtils.subscribePreviewEvents(canvas, setPreview);
            canvasUtils.subscribeCanvasEvents(canvas, canvasName, setToolbarSettings);
        }
    }, [canvas]);

    useEffect(() => {
        canvasUtils.setCanvasDimensions(
            canvasSizesArray,
            canvasSizeIndex,
            canvas,
            dimension,
            setToolbarSettings,
            setDimension
        );
    }, [canvasSizeIndex]);

    useEffect(() => {
        if (canvas && !loadingData)
            canvasUtils.updateCanvasItems(
                canvas,
                setToolbarSettings,
                items,
                ps,
                canvasItems,
                setCanvasItems,
                setCounter,
                setCanvasLoad,
                canvasName,
                canvasSizesArray,
                updateCanvasTotalPrice,
                'freeItems'
            );
    }, [canvasLayout, items, ps]);

    const setCanvasType = (sizeCheck: number) =>
        canvasSizeIndex === sizeCheck && canvasLayout === 'free' ? 'show' : 'hide';

    const setLayout = (wordCheck: string | undefined) => {
        if (!wordCheck) return canvasLayout === 'grid' ? 'show' : 'hide';
        else return canvasLayout === wordCheck ? 'on' : 'off';
    };

    const doneCropping = (cropped: string) => {
        if (cropModal.item && canvas)
            canvasUtils.onCropDone(cropped, canvas, canvasName, setToolbarSettings, setCropModal);
    };

    return (
        <div className={`canvas ${canvasSizeIndex ? 'opened' : 'closed'} canvas-type-${type}`}>
            {cropModal.show && (
                <CropModal
                    show={cropModal.show}
                    onDone={doneCropping}
                    onClose={() => setCropModal({ show: false })}
                    image={cropModal.item}
                    refCanvasItem={cropModal.canvasItem}
                />
            )}

            <div
                className="canvas-wrapper"
                style={{ width: dimension, height: dimension + (canvasSizeIndex === 2 ? 60 : 0) }}
            >
                {canvasLoading && <Loader />}

                <div
                    className="sizeToggle resize"
                    onClick={() => {
                        canvas &&
                            canvasUtils.toggleSize(
                                canvas,
                                canvasSizeIndex,
                                setCanvasSizeIndex,
                                setRoomStylingTypeAction,
                                canvas.boardType ?? stylingRoomState
                            );
                    }}
                />
                <div
                    className="sizeToggle close"
                    onClick={() => canvasUtils.closeCanvas(setCanvasSizeIndex)}
                />

                {canvas && toolbarSettings.show && (
                    <CanvasToolbar
                        top={toolbarSettings.y}
                        left={toolbarSettings.x}
                        width={undefined}
                        onClick={(action: string) =>
                            canvasUtils.onToolbarAction(
                                action,
                                canvas,
                                setCanvasItems,
                                canvasItems,
                                items,
                                canvasName,
                                setToolbarSettings,
                                removeFromCanvas,
                                setCropModal,
                                updateCanvasTotalPrice
                            )
                        }
                        clearBackground={
                            (
                                (
                                    canvas as fabric.Canvas
                                ).getActiveObject() as canvasTypes.ICanvasObject
                            )?.clearBackground
                        }
                        disabled={toolbarSettings.loading}
                    />
                )}
                <div className={`board ${setCanvasType(2)}`}>
                    <FabricJSCanvas onReady={onReady} />
                </div>
                <div className={`preview ${setCanvasType(1)}`}>
                    {preview && <BootstrapImage src={preview} />}
                </div>

                <CanvasGrid className={setLayout(undefined)} canvasName={canvasName} />

                {canvasSizeIndex > 1 && (
                    <div className="buttons">
                        <div className="layout">
                            <GridIcon
                                setLayout={() => setLayout('grid')}
                                setCanvasLayout={() => setCanvasLayout('grid')}
                                setCanvasSizeIndex={setCanvasSizeIndex}
                                isMoodboard={isMoodboard}
                            />
                            <div
                                className={`layout-btn free ${setLayout('free')}`}
                                onClick={() => setCanvasLayout('free')}
                            />
                        </div>
                        <div className="total">
                            {styling.canvas.total.replace(
                                '%total%',
                                `${canvasLayout === 'free' ? itemsTotalPrice : psTotalPrice}`
                            )}
                        </div>
                        <div
                            className={`save ${
                                canvasUtils.checkDisableSave(canvasLayout, items, ps)
                                    ? 'disabled'
                                    : ''
                            }`}
                            onClick={() => {
                                if (canvas)
                                    return canvasUtils.onCanvasSave(
                                        canvasLayout,
                                        onSave,
                                        canvas,
                                        setToolbarSettings
                                    );
                            }}
                        >
                            {styling.canvas.save}
                        </div>
                    </div>
                )}
            </div>
            <div className="minimized-buttons">
                <div className="buttons">
                    <div className="layout">
                        <GridIcon
                            setCanvasSizeIndex={setCanvasSizeIndex}
                            setCanvasLayout={setCanvasLayout}
                            openCanvas={() =>
                                canvasUtils.openCanvas(setCanvasSizeIndex, setCanvasLayout, 'grid')
                            }
                            counter={counter}
                            isMoodboard={isMoodboard}
                        />
                        <div
                            className="layout-btn free"
                            onClick={() =>
                                canvasUtils.openCanvas(setCanvasSizeIndex, setCanvasLayout, 'free')
                            }
                        >
                            {counter.items > 0 && (
                                <div className="items-counter">{counter.items}</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PersistentCanvas;
