import './style.scss';

import { inbox } from 'content.json';
import React, { useEffect, useState } from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import Config from 'services/Config';
import { Client, Stylist } from 'types/user';

import Boards from './Boards';
import Notes from './Notes';
import Photos from './Photos';
import { Quiz } from './Quiz';
import Style from './Style';

interface IClientInfo {
    client: Client;
    stylist: Stylist;
}

const ClientInfo: React.FC<IClientInfo> = ({ client, stylist }) => {
    const [uuid, setUuid] = useState(client.user_uuid ?? '');
    const [tab, setTab] = useState<string | any>('photos');

    const isDev = Config.isDev();
    useEffect(() => {
        setUuid(client?.user_uuid ?? '');
        if (tab !== 'photos') setTab('photos');
    }, [client, uuid]);

    return (
        <Container className="client-info">
            <Tabs
                id={tab}
                activeKey={tab}
                onSelect={(tab: string) => {
                    setTab(tab);
                }}
            >
                <Tab eventKey="photos" title={inbox.bookings.info.tabs.photos}>
                    {tab === 'photos' && <Photos client={client} uuid={uuid} />}
                </Tab>
                <Tab eventKey="style" title={inbox.bookings.info.tabs.style}>
                    {tab === 'style' && <Style uuid={uuid} />}
                </Tab>
                <Tab eventKey="boards" title={inbox.bookings.info.tabs.boards}>
                    {tab === 'boards' && <Boards uuid={uuid} stylist={stylist} />}
                </Tab>
                <Tab eventKey="notes" title={inbox.bookings.info.tabs.notes}>
                    {tab === 'notes' && <Notes uuid={uuid} stylist={stylist} />}
                </Tab>
                {isDev && (
                    <Tab eventKey="quiz" title={inbox.bookings.info.tabs.quiz}>
                        {tab === 'quiz' && <Quiz client={client} uuid={uuid} />}
                    </Tab>
                )}
            </Tabs>
        </Container>
    );
};

export default ClientInfo;
