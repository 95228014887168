export const MIXPANEL_EVENTS = {
    ITEM_ORDERED: 'Item Ordered',
    ITEM_CLICKS: 'Item Clicks',
    END_SESSION_REQUEST: 'End Session Request',
    LOOK_VIEWS: 'Look Views',
    LOOK_CLICKS: 'Look Clicks',
    LOOK_ADDED_TO_FAVORITE: 'Look Added to Favorite',
    USER_TYPE: 'User Type',
    FIRST_NAME: 'First name',
    LAST_NAME: 'Last name',
    USER_STATUS: 'User status',
    BIRTHDATE: 'Birthdate',
    GENDER: 'Gender',
    BODY_TYPE: 'Body Type',
    COUNTRY: 'Country',
    LOGIN: 'Login',
    LAST_BOOKING_DATE: 'Last Booking Date',
    ONBOARDING_INTRO_VIEWS: 'Onboarding Intro Views',
    ONBOARDING_GENDER_VIEWS: 'Onboarding Gender Views',
    FIRST_ORDER_DATE: 'First Order Date',
    BOOKING_COUNT: 'Booking Count',
    ONBOARDING_GENDER_SELECTION: 'Onboarding Gender Selection',
    ONBOARDING_BODY_TYPE_VIEWS: 'Onboarding Body Type Views',
    ONBOARDING_BODY_TYPE_SELECTION: 'Onboarding Body type Selection',
    ONBOARDING_STYLE_VIEWS: 'Onboarding Style Views',
    ONBOARDING_STYLE_SELECTION: 'Onboarding Style Selection',
    ONBOARDING_BRANDS_VIEWS: 'Onboarding Brands Views',
    ONBOARDING_BRANDS_SELECTION: 'Onbaording Brands Selection',
    MEET_MY_MATCH: 'Meet My Match',
    STYLIST_MATCH_VIEWS: 'Stylist Match Views',
    MEET_STYLIST: 'Meet Stylist',
    STYLIST_PROFILE_VIEWS: 'Stylist Profile Views',
    BOOK_STYLIST: 'Book Stylist',
    STYLING_GOALS_VIEWS: 'Styling Goals Views',
    STYLING_GOAL_SELECTION: 'Styling Goal Selection',
    PLAN_VIEWS: 'Plan Views',
    PLAN_SELECTION: 'Plan selection',
    CHECKOUT_VIEWS: 'Checkout Views',
    PAYMENT_COMPLETED: 'Payment completed',
    SESSION_STARTED: 'Session Started',
    STARTED_A_QUIZ: 'Started a Quiz',
    COMPLETED_A_QUIZ: 'Completed a Quiz',
    LOOK_CLICK: 'Look Click',
    ITEM_ADDED_TO_CART: 'Item Added To Cart',
    ITEM_VIEWS: 'Item Views',
    ITEM_CHECKOUT_CLICKS: 'Item Checkout Clicks',
    FEED_BOOK_STYLIST: 'Feed Book Stylist',
    FEED_PAGE_VIEWS: 'Feed Page Views',
    END_SESSION_RESPONSE: 'End Session Response',
    SESSION_ENDED: 'Session Ended',
    ORDER_COMPLETED: 'Order Completed',
    HOW_IT_WORKS_PAGE_VIEW: 'How it Works Page Views',
    HOW_IT_WORKS_PRICING_PLANS: 'How it Works Pricing Plans',
    HOW_IT_WORKS_GET_STYLING: 'How it Works Get Styling',
    HOW_IT_WORKS_HELP_CENTER: 'How it Works Help Center',
    HOW_IT_WORKS_HELP_WIDGET: 'How it Works Help Widget',
    HOW_IT_WORKS_REVIEWS_NAVIGATION: 'How it Works Reviews Navigation'
};
