import { Action, applyMiddleware, combineReducers, compose, createStore, Reducer } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import reducers from '../redux/reducers';
import logMiddleware from './middlewares/log';
import { IStore } from './types';

const persistConfig = {
    key: 'wishi',
    storage,
    blacklist: ['config', 'style', 'payments', 'stylists', 'users', 'stylingRoom']
};

const usersPersistConfig = {
    key: 'users',
    storage: storage,
    blacklist: [
        'twilioInit',
        'messagesCount',
        'cart',
        'cartTotal',
        'user',
        'closet',
        'orders',
        'photos',
        'looks',
        'closetTags',
        'error'
    ]
};

const stylingRoomPersistConfig = {
    key: 'stylingRoom',
    storage: storage,
    whitelist: ['type']
};

const persistedReducer = persistReducer(
    persistConfig,
    combineReducers({
        ...reducers,
        users: persistReducer(usersPersistConfig, reducers.users as any),
        stylingRoom: persistReducer(stylingRoomPersistConfig, reducers.stylingRoom)
    })
);
// @ts-ignore
const store: IStore = createStore(
    persistedReducer,
    compose(
        applyMiddleware(thunk),
        applyMiddleware(logMiddleware),
        window.__REDUX_DEVTOOLS_EXTENSION__
            ? window.__REDUX_DEVTOOLS_EXTENSION__()
            : (f: unknown) => f
    )
);
store.reducers = reducers;
store.loadReducer = (name: string, reducer: Reducer<any, Action<any>>) => {
    store.reducers[name] = reducer;
    store.replaceReducer(combineReducers(store.reducers));
};

store.hasReducer = (name: string) => name in store.reducers;

const persistor = persistStore(store);

export { persistor, store };
