import debounce from 'lodash/debounce';
import React, { useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Reducer } from 'redux';
import { Dispatch } from 'redux';

import { store } from '../store';

export const useWithDispatch = <T extends any[], U>(
    fn: (dispatch: Dispatch<any>, ...args: T) => U
) => {
    const dispatch = useDispatch();
    return (...args: T): U => fn(dispatch, ...args);
};
export const useWindowSize = () => {
    const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
    useLayoutEffect(() => {
        function updateSize() {
            const width = window.innerWidth;
            const height = window.innerHeight;
            setSize([width, height]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
};
export function usePrevious<T>(value: T): T {
    const ref = React.useRef<T>();
    React.useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current as T;
}

export const useReducer = (name: string, reducer: Reducer) => {
    React.useMemo(() => {
        if (!store.hasReducer?.(name) && store.loadReducer) {
            store.loadReducer(name, reducer);
        }
    }, []);
};
export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useLayoutEffect(() => {
        const updateSize = (): void => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', debounce(updateSize, 250));
        return (): void => window.removeEventListener('resize', updateSize);
    }, []);
    return isMobile;
};

export const useIsTablet = () => {
    const [isTablet, setIsTablet] = useState(window.innerWidth < 992 && window.innerWidth > 567);
    useLayoutEffect(() => {
        const updateSize = (): void => {
            setIsTablet(window.innerWidth < 992 && window.innerWidth > 567);
        };
        window.addEventListener('resize', debounce(updateSize, 250));
        return (): void => window.removeEventListener('resize', updateSize);
    }, []);
    return isTablet;
};

export * from './users';
