import './style.scss';

import { useWithDispatch } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { MIXPANEL_EVENTS, trackEvent } from 'services/mixpanel';
import { setMixpanelStore } from 'store/common/actions';
import { useCommonStore } from 'store/common/reducer';

import { Loader, Page, PageNavbar } from '../../components';
import { Tracking } from '../../services';
import Sections from './Sections';

const Stylist = ({ user, stylist, match, loadStylist, loadUserMatches, loading, toggleModal }) => {
    const history = useHistory();
    const reviewsRef = React.createRef();
    const aboutRef = React.createRef();
    const workRef = React.createRef();
    const NAV_ANCHORS = [
        { ref: workRef, label: 'Looks' },
        { ref: aboutRef, label: 'About' },
        { ref: reviewsRef, label: 'Reviews' }
    ];
    const matches = useCommonStore((store) => store.stylistsMatchs);
    const bookingSource = useCommonStore((store) => store.bookingSource);
    const mixpanelStore = useCommonStore((store) => store.mixpanelStore);
    const loadUserMatchesAction = useWithDispatch(loadUserMatches);
    const [tracked, setTracked] = useState(false);
    const setMixpanelStoreAction = useWithDispatch(setMixpanelStore);

    const onNavSelect = (ref) => ref.current.scrollIntoView({ behavior: 'smooth' });

    useEffect(() => {
        if (stylist.uuid)
            Tracking.tag({
                event: 'eec.detail',
                ecommerce: {
                    detail: {
                        actionField: { list: 'Stylist Recommendations' },
                        products: [
                            {
                                id: stylist.uuid,
                                name: stylist.name,
                                category: '/category/stylist/',
                                dimension3: 'Ecommerce'
                            }
                        ]
                    }
                }
            });
    }, [stylist]);

    useEffect(() => {
        if (!matches.length) {
            loadUserMatchesAction(user);
        }
    }, [user]);

    useEffect(() => {
        if (stylist.uuid) {
            if (matches?.length && !tracked) {
                setMixpanelStoreAction({
                    onboarding: {
                        ...mixpanelStore.onboarding,
                        'Booking Source': bookingSource
                    }
                });
                trackEvent({
                    name: MIXPANEL_EVENTS.STYLIST_PROFILE_VIEWS,
                    properties: {
                        ...mixpanelStore.onboarding,
                        'Booking Source': bookingSource
                    }
                });
                Tracking.tag({
                    event: 'eec.detail',
                    ecommerce: {
                        detail: {
                            actionField: { list: 'Stylist Recommendations' },
                            products: [
                                {
                                    id: stylist.uuid,
                                    name: stylist.name,
                                    category: '/category/stylist/',
                                    dimension3: 'Ecommerce'
                                }
                            ]
                        }
                    }
                });
                setTracked(true);
            }
        }
    }, [stylist, matches]);

    useEffect(() => {
        Tracking.facebook('track', 'PageView', {
            id: match.params.id,
            content_ids: [match.params.id],
            content_type: 'product'
        });
        loadStylist(match.params.id);
    }, [loadStylist, loadUserMatches, match]);

    const contactStylist = () => {
        user?.user_uuid
            ? toggleModal({ type: 'Contact' })
            : toggleModal({
                  type: 'Signin',
                  url: `/stylist/${stylist.uuid}/profile`
              });
    };

    return (
        <Page footer={false}>
            {(loading || !stylist.uuid) && <Loader />}
            {user && stylist && user.user_uuid === stylist.uuid && navigator.share && (
                <Sections.ShareBar />
            )}

            <Container className="stylist">
                <PageNavbar
                    className="d-block d-sm-none"
                    anchors={NAV_ANCHORS}
                    onSelect={onNavSelect}
                    onBack={() => history.push('/stylistSearch')}
                />

                <Sections.StylistHeader />

                <div ref={workRef}>
                    <Sections.Work />
                </div>

                <Sections.Recommendation />

                <div ref={aboutRef}>
                    <Sections.Experience />
                </div>

                <Sections.Expertise />

                <div ref={reviewsRef}>
                    <Sections.Reviews />
                </div>

                <Sections.StylingExperience />

                <Sections.Matches />

                {!loading && Object.keys(stylist).length > 0 && (
                    <Sections.BookBar onContactStylist={contactStylist} />
                )}
            </Container>
        </Page>
    );
};

export default Stylist;
