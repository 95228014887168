import './style.scss';

import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';

import { Payments } from '../../../services';

const stripe = require('stripe')(Payments.getStripeKey());
stripe.applePayDomains.create({ domain_name: 'wishi.me' });

const PaymentRequestButton = ({
    stripe,
    price,
    show = false,
    onShow,
    onSuccess,
    shipping = {},
    onClick,
    disabled = false,
    props = {}
}) => {
    const [paymentRequest, setPaymentRequest] = useState(null);
    useEffect(() => {
        if (stripe) {
            setStripe();
        }
    }, [stripe]);
    const getPrice = (_price) => {
        if (_price && typeof _price.replace === 'function') {
            return parseFloat(Number(_price.replace(/,/g, '')).toFixed());
        }
        return _price;
    };
    useEffect(() => {
        if (stripe && paymentRequest) {
            paymentRequest.update({
                total: {
                    label: props.stripeLabel
                        ? props.stripeLabel
                        : `Styling session ${props.user ? `for ${props.user.first_name}` : ''}`,
                    amount: getPrice(price)
                }
            });
        }
    }, [price, paymentRequest]);

    const setStripe = async () => {
        if (stripe) {
            const req = {
                country: 'US',
                currency: 'usd',
                requestPayerName: shipping.show,
                requestPayerEmail: shipping.show,
                requestPayerPhone: shipping.show,
                requestShipping: shipping.show,
                total: {
                    label: props.stripeLabel
                        ? props.stripeLabel
                        : `Styling session ${props.user ? `for ${props.user.first_name}` : ''}`,
                    amount: getPrice(price)
                }
            };
            const pr = stripe.paymentRequest(req);

            pr.on('token', (result) => {
                const { token, complete } = result;
                complete('success');
                onSuccess(token.id, { ...result, props });
            });

            pr.on('shippingaddresschange', async (ev) => {
                const { country, city, postalCode, region = '' } = ev.shippingAddress;
                const price = await shipping.price({
                    data: { state: region, country, city, zipcode: postalCode }
                });

                if (!postalCode) {
                    ev.updateWith({ status: 'invalid_shipping_address' });
                } else {
                    ev.updateWith({
                        status: 'success',
                        total: {
                            amount: parseInt(Number(price.total * 100).toFixed()),
                            label: price.item.name
                        },
                        shippingOptions: [
                            {
                                id: 'shipping',
                                label: 'Wishi',
                                detail: 'Wishi',
                                amount: parseInt(Number(price.shipping * 100).toFixed())
                            }
                        ]
                    });
                }
            });

            const canMakePaymentRes = await pr.canMakePayment();
            if (canMakePaymentRes) {
                onShow();
                setPaymentRequest(pr);
            } else {
                setPaymentRequest(null);
            }
        }
    };
    return paymentRequest && show ? (
        <PaymentRequestButtonElement
            options={{
                style: {
                    paymentRequestButton: {
                        type: 'default',
                        theme: 'dark',
                        height: `${props.height ? props.height : 47}px`
                    }
                },
                paymentRequest
            }}
            className={`StripeElement payment-request-button ${disabled ? 'disabled' : ''}`}
            onClick={onClick ? onClick : (event) => event}
        />
    ) : null;
};

export default PaymentRequestButton;
