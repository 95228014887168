import { pricing } from 'content.json';

import { IActionWithPayload } from '../types';

export enum ActionTypes {
    SET_MODEL = 'payments/SET_MODEL'
}
export type PaymentModel = keyof typeof pricing.plans[number]['price'];
export interface PaymentsState {
    model: PaymentModel;
}

export type Actions = IActionWithPayload<ActionTypes.SET_MODEL, PaymentModel>;
