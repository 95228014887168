import './style.scss';

import React from 'react';

import { styling } from '../../../content';

const Empty = ({ section, name = '' }) => {
    const content = {
        shop: EmptyShop,
        store: EmptyStore,
        closet: EmptyCloset,
        favorites: EmptyFavorites,
        inspiration: EmptyInspiration,
        boards: EmptyBoards,
        cart: EmptyCart,
        purchases: EmptyPurchases
    };
    const Component = content[section];
    return (
        section && (
            <div className="styling-room-empty">
                <Component name={name} />
            </div>
        )
    );
};

export default Empty;

const EmptyShop = () => styling.empty.shop;

const EmptyStore = () => <div dangerouslySetInnerHTML={{ __html: styling.empty.store }}></div>;

const EmptyCloset = ({ name = '' }) => styling.empty.closet.split('%name%').join(name);

const EmptyCart = ({ name = '' }) => styling.empty.cart.split('%name%').join(name);

const EmptyPurchases = ({ name = '' }) => styling.empty.purchases.split('%name%').join(name);

const EmptyFavorites = () => styling.empty.favorites;

const EmptyInspiration = () => styling.empty.inspiration;

const EmptyBoards = () => styling.empty.boards;
