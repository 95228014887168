import { Cookies, Style } from '../../../services';

export const LOAD_START = 'LOAD_START';
export const LOAD_FAILED = 'LOAD_FAILED';

export const LOAD_STYLISTS_SUCCESS = 'LOAD_STYLISTS_SUCCESS';
export const LOAD_STYLISTS_FAILED = 'LOAD_STYLISTS_FAILED';

export const TOGGLE_FILTERS = 'TOGGLE_FILTERS';
export const UPDATE_STYLE_SELECTED_FILTERS = 'UPDATE_STYLE_SELECTED_FILTERS';
export const SET_FILTER_RESULTS_COUNT = 'SET_FILTER_RESULTS_COUNT';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const CLEAR_MATCHES = 'CLEAR_MATCHES';

export const LOAD_OUTFIT_START = 'LOAD_OUTFIT_START';
export const UPDATE_OUTFIT = 'UPDATE_OUTFIT';
export const LOAD_OUTFIT_FAILED = 'LOAD_OUTFIT_FAILED';
export const CLEAR_OUTFIT = 'CLEAR_OUTFIT';

export const LOAD_ITEM_START = 'LOAD_ITEM_START';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const LOAD_ITEM_FAILED = 'LOAD_ITEM_FAILED';

export const UPDATE_TAGS = 'UPDATE_TAGS';
export const UPDATE_EVENTS = 'UPDATE_EVENTS';

export const loadStart = () => ({
    type: LOAD_START
});

export const loadFailed = (error) => ({
    type: LOAD_FAILED,
    payload: { error }
});

export const loadStylistsSuccess = (stylists) => ({
    type: LOAD_STYLISTS_SUCCESS,
    payload: { stylists }
});

export const updateFilters = (selectedFilters) => ({
    type: UPDATE_STYLE_SELECTED_FILTERS,
    payload: { selectedFilters }
});

export const updateFilterResultsCount = (count) => ({
    type: SET_FILTER_RESULTS_COUNT,
    payload: { count }
});

export const clearFilters = () => ({
    type: CLEAR_FILTERS
});

export const toggleFilters = () => ({
    type: TOGGLE_FILTERS
});

export const loadOutfitStart = () => ({
    type: LOAD_OUTFIT_START
});

export const updateOutfit = (outfit) => ({
    type: UPDATE_OUTFIT,
    payload: { outfit }
});

export const clearOutfit = () => ({
    type: CLEAR_OUTFIT
});

export const loadItemStart = () => ({
    type: LOAD_ITEM_START
});

export const updateItem = (item = {}) => ({
    type: UPDATE_ITEM,
    payload: { item }
});

export const updateTags = (type, tags = []) => ({
    type: UPDATE_TAGS,
    payload: { type, tags }
});

export const updateEvents = (events = []) => ({
    type: UPDATE_EVENTS,
    payload: { events }
});

// TODO: move this one to new code...
export const setFilter = (update) => {
    return async (dispatch, getState) => {
        const {
            style: { selectedFilters }
        } = getState();
        let _filters = { ...selectedFilters };

        if (!_filters[update.key]) {
            _filters[update.key] = [];
        }
        if (_filters[update.key].find((item) => item.key === update.value.key)) {
            _filters[update.key] = _filters[update.key].filter(
                (filter) => filter.key !== update.value.key
            );
        } else {
            _filters[update.key].push(update.value);
        }
        if (!_filters[update.key].length) delete _filters[update.key];

        dispatch(updateFilters(_filters));
    };
};

export const loadOutfit = (outfit_uuid) => {
    return async (dispatch) => {
        dispatch(clearFilters());
        dispatch(loadOutfitStart());
        try {
            let uuid = null;
            const cookie = Cookies.get('user');
            if (cookie) {
                uuid = Cookies.get('user').uuid;
            }
            const { data } = await Style.getOutfit(outfit_uuid, uuid);
            dispatch(updateOutfit(data));
        } catch (error) {
            dispatch(loadFailed(error));
        }
    };
};

export const loadItem = (item_uuid) => {
    return async (dispatch) => {
        dispatch(loadItemStart());
        try {
            let user_uuid = null;
            const cookie = Cookies.get('user');
            if (cookie) {
                user_uuid = Cookies.get('user').uuid;
            }
            const { data } = await Style.getItem(item_uuid, user_uuid);
            dispatch(updateItem(data));
        } catch (error) {
            dispatch(loadFailed(error));
        }
    };
};

export const loadTags = (gender = 'female', type = 'style') => {
    return async (dispatch) => {
        dispatch(loadStart());
        try {
            const { data } = await Style.tags(gender, type);
            dispatch(updateTags(type, data));
        } catch (error) {
            dispatch(loadFailed(error));
        }
    };
};

export const loadEvents = () => {
    return async (dispatch) => {
        dispatch(loadStart());
        try {
            const { data } = await Style.events();
            dispatch(updateEvents(data));
        } catch (error) {
            dispatch(loadFailed(error));
        }
    };
};
