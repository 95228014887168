import '../style.scss';

import React from 'react';
import { Badge } from 'react-bootstrap';
import { Formatter } from 'services';
import { ClearFilterObject, FilterOptionsParameters, StylingRoomSection } from 'types/styling';

interface IFilterBadge {
    view: StylingRoomSection;
    selectedFilters: Record<string, FilterOptionsParameters[]>;
    clearFilter: (clearFilter: ClearFilterObject) => void;
}

const FilterBadge: React.FC<IFilterBadge> = ({ selectedFilters, view, clearFilter }) => {
    const keyFilters = Object.keys(selectedFilters).filter(
        (key) => key !== 'maxPrice' && key !== 'max_budget'
    );
    const isShop = view === StylingRoomSection.shop;
    const isStore = view === StylingRoomSection.store;

    return (
        <div className="filter-badges">
            {keyFilters.map((key) =>
                selectedFilters[key].map((filter) => (
                    <Badge variant="light" key={filter.key}>
                        <div
                            id="badge-close"
                            className="close"
                            onClick={() =>
                                clearFilter({
                                    key,
                                    filter: {
                                        key: key,
                                        value: filter
                                    }
                                })
                            }
                        />
                        {isShop && key === 'minPrice' ? (
                            <span>
                                {Formatter.price(selectedFilters?.minPrice[0]?.key)}-
                                {Formatter.price(selectedFilters?.maxPrice[0]?.key)}
                            </span>
                        ) : isStore && key === 'min_budget' ? (
                            <span>
                                {Formatter.price(selectedFilters?.min_budget[0]?.key)}-
                                {Formatter.price(selectedFilters?.max_budget[0]?.key)}
                            </span>
                        ) : (
                            filter.text
                        )}
                    </Badge>
                ))
            )}
        </div>
    );
};

export default FilterBadge;
