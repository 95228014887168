import 'react-image-crop/dist/ReactCrop.css';
import './style.scss';

import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ReactCrop from 'react-image-crop';

import { Loader } from '../../components';
import { styling } from '../../content';

const CanvasCropModal = ({ show, image, onDone, onClose, refCanvasItem }) => {
    const [process, setProcess] = useState(false);
    const [crop, setCrop] = useState({ unit: '%', width: 100, height: 100 });
    const [src, setSrc] = useState();
    const [cropped, setCropped] = useState(null);
    let imageRef = useRef(null);
    let cropRef = useRef(null);

    useEffect(() => {
        if (image) {
            setProcess(true);
            setSrc(image);
        }
    }, [image]);

    useEffect(() => {
        if (!cropped || !cropRef.current || !imageRef.current) return;

        const image = imageRef.current;
        const canvas = cropRef.current;
        const crop = cropped;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
        if (refCanvasItem.transformations) {
            refCanvasItem.transformations.crop = {
                height: cropped?.height,
                width: cropped?.width,
                x: crop.x ?? 0,
                y: crop.y ?? 0
            };
        }

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
    }, [cropped]);

    const onImageLoaded = useCallback((img) => {
        imageRef.current = img;
        setProcess(false);
    }, []);

    const onCropComplete = (crop) => setCropped(crop);

    const onCropDone = () => onDone(cropRef.current.toDataURL());

    const onCropChange = (crop) => setCrop(crop);

    return (
        <Modal show={show} onHide={onClose} className="canvas-crop-modal">
            <Modal.Body>
                {process && <Loader />}
                {src && (
                    <ReactCrop
                        src={src}
                        crop={crop}
                        onImageLoaded={onImageLoaded}
                        onComplete={onCropComplete}
                        onChange={onCropChange}
                    />
                )}
                <canvas
                    className="cropped"
                    ref={cropRef}
                    style={{
                        width: Math.round(cropped?.width ?? 0),
                        height: Math.round(cropped?.height ?? 0)
                    }}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={onClose}>
                    {styling.canvas.crop.cancel}
                </Button>
                <Button variant="warning" onClick={onCropDone}>
                    {styling.canvas.crop.done}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CanvasCropModal;
