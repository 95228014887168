import './style.scss';

import { useEffect, useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Cookies } from 'services';
import { sentryException } from 'services/SentryLogging';
import { retrieveCanvasData } from 'services/utils/canvas-utils';

import { Formatter } from '../../services';

const CanvasGrid = ({
    ps = [],
    removeFromCanvas,
    canvasSizesArray,
    canvasSizeIndex,
    className = '',
    canvasName,
    addToCanvas,
    updateCanvasTotalPrice
}) => {
    const [size, setSize] = useState(150);
    const [PSItems, setPSItems] = useState([]);
    const [numberOfFilledGrids, setNumberOfFilledGrids] = useState(0);

    const retriveItems = (psItems) =>
        new Promise((resolve, reject) => {
            resolve(psItems.map((item) => !item.id.includes('empty') && addToCanvas(item, 'grid')));
            reject((reason) => {
                const user = Cookies.get('user');
                sentryException(reason, 'Fail To Retrive Canvas Items', `User Id: ${user?.uuid}`);
            });
        });

    const pullPSData = async (psData) => {
        await retriveItems(psData);
    };

    useEffect(() => {
        const maxDimension = window.innerHeight - 150;
        setSize(
            canvasSizesArray[canvasSizeIndex] < maxDimension
                ? canvasSizesArray[canvasSizeIndex]
                : maxDimension
        );
    }, [canvasSizeIndex]);

    useEffect(() => {
        const psData = retrieveCanvasData(canvasName, 'ps');
        if (psData) {
            const parsedPSItems = JSON.parse(psData);
            pullPSData(parsedPSItems);
        }
    }, []);

    useEffect(() => {
        let arr = ps.filter((item) => !!item);
        if (arr.length < 9) for (let i = arr.length; i < 9; i++) arr.push({ id: `empty_${i}` });
        updateCanvasTotalPrice();
        setPSItems(arr);
        const filledGrids = arr.reduce((acc, item) => {
            if (!item.id.includes('empty')) acc.push(item);
            return acc;
        }, []);
        setNumberOfFilledGrids(filledGrids.length);
        if (filledGrids.length > 0)
            localStorage.setItem(`${canvasName}_${'ps'}`, JSON.stringify(filledGrids));
    }, [ps]);

    const showPrice = (item) => item.price && item.price !== '0.00' && item.price !== 0;

    const showDetails = (item) => item.brand_name && showPrice(item) && canvasSizeIndex > 1;

    const isEmpty = (item) => item.id && item.id.indexOf('empty') > -1;
    return (
        <div className={`canvas-grid ${className}`} style={{ width: size, height: size }}>
            <Container fluid>
                <Row>
                    {PSItems.map(
                        (item) =>
                            item && (
                                <Col
                                    xs={4}
                                    key={item.id}
                                    className={`canvas-grid-item ${isEmpty(item) ? 'empty' : ''}`}
                                >
                                    <div
                                        className="canvas-grid-remove"
                                        onClick={() => {
                                            if (numberOfFilledGrids === 1)
                                                localStorage.removeItem(`${canvasName}_${'ps'}`);
                                            removeFromCanvas(item.id);
                                        }}
                                    />
                                    {item.picture ? (
                                        <Image
                                            src={item.picture}
                                            className={showDetails(item) ? 'with-details' : ''}
                                            fluid
                                        />
                                    ) : (
                                        <span />
                                    )}
                                    {showDetails(item) && (
                                        <div className="details">
                                            <p className="title">{item.brand_name}</p>
                                            <p>{showPrice(item) && Formatter.price(item.price)}</p>
                                            {item.buy_url_short && (
                                                <a
                                                    href={item.buy_url}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {item.merchant_name || item.buy_url_short}
                                                </a>
                                            )}
                                        </div>
                                    )}
                                </Col>
                            )
                    )}
                </Row>
            </Container>
        </div>
    );
};

export default CanvasGrid;
