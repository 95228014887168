import './style.scss';

import { JSONObject } from '@twilio/conversations';
import ClientInfo from 'containers/ClientInfo';
import { inbox } from 'content.json';
import { useWithDispatch } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { sentryException } from 'services/SentryLogging';
import { isArchive, isArchiveOrInquiries } from 'services/utils/bookings-utils';
import {
    archiveSession,
    loadBookings,
    loadBookingsConfig,
    loadClients,
    loadSingleBooking,
    setError,
    setFilter,
    updateSelectedBookingId,
    updateSingleBooking,
    updateUnreadMessages
} from 'store/booking/actions';
import { useBookingStore } from 'store/booking/reducer';
import { Filter } from 'store/booking/types';
import { useServicesStore } from 'store/services/reducer';
import { toggleModal } from 'store/ui/actions';
import { useUserStore } from 'store/user/reducer';
import { BookingType, QueueType } from 'types/booking';

import { Stylist, TwilioService, User } from '../../services';
import BookingList from './BookingList';
import BookingsFilter from './BookingsFilter';
import Chat from './Chat';
import QueueTitle from './components/QueueTitle';

const Bookings: React.FC = () => {
    const history = useHistory();
    const listRef = React.createRef();
    const chatRef = React.createRef();
    const [chatLoading, setChatLoading] = useState(false);
    const [chat, setChat] = useState<any | null>(null);
    const [showDetails, setShowDetails] = useState(false);
    const [client, setClient] = useState<any | null>(null);
    const [selectedBooking, setSelectedBooking] = useState<BookingType | null>(null);
    const user = useUserStore((store) => store.user);
    const isTwilioInitialized = useServicesStore((store) => store.isTwilioInitialized);
    const bookings = useBookingStore((store) => store.bookings);
    const totalBookings = useBookingStore((store) => store.totalBookings);
    const bookingfilters = useBookingStore((store) => store.filters);
    const bookingStatus = useBookingStore((store) => store.bookingStatus);
    const clients = useBookingStore((store) => store.clientsForStylist);
    const selectedBookingId = useBookingStore((store) => store.selectedBookingId);
    const currentPage = useBookingStore((store) => store.savedCurrentPage);
    const error = useBookingStore((store) => store.error);
    const archiveSessionAction = useWithDispatch(archiveSession);
    const setFilterAction = useWithDispatch(setFilter);
    const loadClientsAction = useWithDispatch(loadClients);
    const toggleModalAction = useWithDispatch(toggleModal);
    const setErrorAction = useWithDispatch(setError);
    const updateUnreadMessagesAction = useWithDispatch(updateUnreadMessages);
    const updateSelectedBookingIdAction = useWithDispatch(updateSelectedBookingId);
    const updateSingleBookingAction = useWithDispatch(updateSingleBooking);
    const loadBookingConfigAction = useWithDispatch(loadBookingsConfig);
    const loadSingleBookingAction = useWithDispatch(loadSingleBooking);
    const loadBookingsAction = useWithDispatch(loadBookings);
    const loading = false;
    const minScrollIndex = 2;
    const bookingLimitPerPage = 15;
    const [selectedQueue, setSelectedQueue] = useState<QueueType>();
    const [previuosQueue, setpreviuosQueue] = useState<QueueType>();
    const [priceModel, setPriceModel] = useState('');
    const [isSearch, setIsSearch] = useState<boolean | null>(null);
    const isArchiveOrInquiriesQueue = isArchiveOrInquiries(selectedQueue);
    const showBookingQueues = !isArchiveOrInquiriesQueue && !isSearch;
    const shouldUnmountSession =
        bookings && !bookings.length && !loading && !selectedBooking && chat;
    const isInitialSession = selectedBooking && isTwilioInitialized && !chat;
    const archiveInquiries = bookingStatus.inquiriesAndArchive ?? [];

    useEffect(() => {
        loadClientsAction();
        loadBookingConfigAction();
        fetchData({ page: currentPage, search: bookingfilters?.search });
    }, []);

    const fetchData = (filters: Filter, loadBookingCount = true, limit = bookingLimitPerPage) => {
        setSelectedBooking(null);
        setFilterAction(filters);

        const parmasFilter = isArchiveOrInquiriesQueue ? [] : filters?.filter ?? undefined;
        const queue = filters.queue ?? undefined;
        const limitBookings = filters.page ? limit * filters.page : limit;
        const params = { queue, filters: parmasFilter, limit: limitBookings };

        if (filters.search) {
            setIsSearch(true);
            loadSingleBookingAction(filters.search);
        } else {
            setIsSearch(false);
            loadBookingsAction({ params }, loadBookingCount);
        }
    };

    useEffect(() => {
        if (bookings?.length > 0) {
            if (bookingfilters.search || !selectedBooking) scrollToSelectedBooking();
            if (isInitialSession) mountSession();
        }

        if (shouldUnmountSession) {
            chat.unmount();
            setClient(null);
            setSelectedBooking(null);
        }
    }, [bookings]);

    const chatEntered = async (channelSid: string) => await Stylist.chatEntered(channelSid);

    useEffect(() => {
        if (isTwilioInitialized) {
            TwilioService.subscribed = false;
            TwilioService.listenMessages((channel) => {
                updateBookingStatus(channel.sid);
            });
        }
    }, [isTwilioInitialized]);

    useEffect(() => {
        const fetchClientData = () => {
            if (chat) chat.unmount();
            mountSession();
        };
        fetchClientData();

        if (chat && selectedBooking) {
            if (selectedBooking.session.hasUnreadMessages) {
                updateUnreadMessagesAction(selectedBooking.session.sid, false);
            }
            chatEntered(selectedBooking.session.sid);
            if (!isArchiveOrInquiriesQueue)
                updateSelectedBookingIdAction(selectedBooking.session.sid);
        }
    }, [selectedBooking]);

    useEffect(() => {
        if (error) {
            toggleModalAction({
                type: 'Error',
                data: { error: 'Failed to load booking data', callback: setErrorAction() }
            });
        }
    }, [error]);

    const mountSession = () => {
        if (
            selectedBooking &&
            (isTwilioInitialized || (!isTwilioInitialized && selectedBooking.session.uuid))
        ) {
            if (chat) chat.unmount();

            if (selectedBooking && !isArchive(selectedBooking.session.type)) {
                setChatLoading(true);
                const current = new window.ClientChatPlugin({
                    element: chatRef.current as HTMLElement,
                    clientId: user?.user_uuid ?? '',
                    clientInfo: undefined,
                    cartItems: [],
                    selectedSid: selectedBooking.session.sid
                });
                current.on('status-changed', (status: string) => {
                    if (status) {
                        setChatLoading(false);
                    }
                });
                current.on('item_clicked', async (item: string) => {
                    history.push('/item/' + item);
                });
                current.on('chat-error', async (error: Error) => {
                    setChatLoading(false);
                    if (user && user?.is_stylist == 1) {
                        toggleModalAction({
                            type: 'Error',
                            data: { error: 'Failed to load channel' }
                        });
                        sentryException(error, 'Stylist chat error');
                    }
                });
                current.mountApp();
                setChat(current);
            }
        }
    };

    useEffect(() => {
        if (selectedQueue && isArchiveOrInquiriesQueue) {
            // @ts-ignore
            listRef.current.scroll(0, 0);
            fetchData({ queue: selectedQueue });
        }

        if (selectedQueue && selectedQueue === QueueType.previous) {
            const limit = bookingLimitPerPage * currentPage;
            fetchData({ queue: previuosQueue }, true, limit);
            setSelectedQueue(previuosQueue);
            setpreviuosQueue(undefined);
        }
    }, [selectedQueue]);

    const onBookingSelect = async (booking: BookingType) => {
        if (booking?.session?.sid !== selectedBooking?.session?.sid) {
            setSelectedBooking(booking);
            const { data } = await User.get(booking?.client?.uuid);
            setClient(data);
            setPricingModel(booking.session.sid);
            if (!isArchiveOrInquiriesQueue) updateSelectedBookingIdAction(booking.session.sid);
        }
    };

    const setPricingModel = async (channelId: string) => {
        const attributes = (await TwilioService.getConversationDetails(channelId)) as JSONObject;
        if (Object.keys(attributes).length === 0) return;
        const priceModel = attributes?.pricing_model === 'single' ? 'One-Time' : 'Monthly';
        setPriceModel(priceModel);
    };

    const onArchive = async (booking: BookingType | null) => {
        if (loading) return;
        const book = booking?.session?.sid ? booking : selectedBooking;
        // @ts-ignore
        listRef.current.scroll(0, 0);
        if (book?.session?.sid) {
            await archiveSessionAction(book.session.sid, isArchive(book.session.type));
            if (bookings.length > 1) updateArchiveBooking(book.session.sid);
            else if (bookingfilters.search && bookings.length === 1)
                fetchData({ search: book.session.sid });
        }
    };

    const updateArchiveBooking = async (channelId: string) => {
        const bookingIndex = bookings.findIndex((booking) => booking.session.sid === channelId);
        if (bookingIndex != -1) {
            const nextBooking =
                bookingIndex + 1 < bookings.length ? bookings[bookingIndex + 1] : bookings[0];
            setSelectedBooking(nextBooking);

            const elm = document.getElementById(nextBooking.session.sid);
            if (elm && bookingIndex + 1 > minScrollIndex) elm.scrollIntoView();

            const { data } = await User.get(nextBooking.client.uuid);
            setClient(data);
        }
    };

    const onBack = () => {
        setSelectedBooking(null);
        setSelectedQueue(QueueType.previous);
    };

    const updateBookingStatus = async (channelId: string) => {
        if (user?.is_stylist) await updateSingleBookingAction(channelId);
    };

    const scrollToSelectedBooking = () => {
        if (selectedBookingId && !isArchiveOrInquiriesQueue) {
            const bookingIndex = bookings.findIndex(
                (booking) => booking.session.sid === selectedBookingId
            );

            setTimeout(() => {
                const elm = document.getElementById(selectedBookingId);
                if (!elm || bookingIndex === -1) onBookingSelect(bookings[0]);
                else {
                    if (elm && bookingIndex > minScrollIndex) elm.scrollIntoView();
                    onBookingSelect(bookings[bookingIndex]);
                }
            }, 1300);
        } else onBookingSelect(bookings[0]);
    };

    return (
        <Container className="bookings" fluid>
            <Row>
                <Col className="list">
                    <BookingsFilter
                        bookingFilters={bookingStatus.filterCategories}
                        clients={clients}
                        total={totalBookings}
                        onMenuClick={(queueView: QueueType) => {
                            setpreviuosQueue(selectedQueue ?? bookingfilters.queue);
                            setSelectedQueue(queueView);
                        }}
                        fetchData={fetchData}
                        showFilters={showBookingQueues}
                    />

                    {isArchiveOrInquiriesQueue && !isSearch && (
                        <QueueTitle
                            queues={archiveInquiries}
                            selectedQueue={selectedQueue}
                            onBack={onBack}
                        />
                    )}

                    <BookingList
                        bookings={bookings}
                        selectedBooking={selectedBooking}
                        totalBookings={totalBookings}
                        listRef={listRef}
                        selectedQueue={bookingfilters.queue ?? bookingStatus.activeQueue}
                        onArchive={onArchive}
                        showBookingQueues={showBookingQueues}
                        bookingQueues={bookingStatus.queues}
                        onQueueChange={setSelectedQueue}
                        setFilter={fetchData}
                        onBookingSelect={onBookingSelect}
                        bookingLimitPerPage={bookingLimitPerPage}
                    />
                </Col>
                <Chat
                    client={client}
                    selectedBooking={selectedBooking}
                    showDetails={showDetails}
                    setShowDetails={setShowDetails}
                    chatLoading={chatLoading}
                    chatRef={chatRef}
                    onArchive={onArchive}
                    loading={loading}
                    priceModel={priceModel}
                />
                <Col className={`info ${showDetails ? 'show' : 'hide'}`}>
                    <p className="title">{inbox.bookings.details}</p>
                    <div className="close" onClick={() => setShowDetails(false)} />
                    {client && showDetails && (
                        <ClientInfo
                            client={{
                                ...client,
                                request_uuid:
                                    bookings && bookings.length > 0 && selectedBooking
                                        ? selectedBooking.session.uuid
                                        : ''
                            }}
                            stylist={user as any}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    );
};
export default Bookings;
