import { IActionWithPayload } from '../types';

export enum ActionTypes {
    SET_STYLING_ROOM_TYPE = 'room/SET_STYLING_ROOM_TYPE',
    SET_REVISION = 'room/SET_REVISION'
}
export interface Revision {
    name: string;
    outfit_uuid: string;
    items: any[]; // TODO: add type
}
export interface RoomState {
    stylingType: string; //'look'
    revision: Revision | null;
}

export type Actions =
    | IActionWithPayload<ActionTypes.SET_STYLING_ROOM_TYPE, string>
    | IActionWithPayload<ActionTypes.SET_REVISION, Revision>;
