import React from 'react';
import { ItemType } from 'types/item';

import { item as texts } from '../../../content.json';
import { Formatter } from '../../../services';

interface IItemDetails {
    item?: ItemType;
    sizes?: string[] | undefined;
}

const ItemDetails: React.FC<IItemDetails> = ({ item, sizes }) => {
    const retailPrice = parseInt(item?.retail_price ?? '0');
    const salePrice = parseInt(item?.sale_price ?? '0');
    const hasDiscount = salePrice < retailPrice;
    const discountPrice = texts.payment.discount.replace(
        '%price%',
        Formatter.price(retailPrice - salePrice)
    );
    const hasStylistNotes = item?.isGift && item?.stylist_name && item?.notes;

    return (
        <>
            <div className="details">
                <div className="brand">{item?.brand_name}</div>
                <div className="name">{item?.name}</div>
                <div className="prices">
                    {retailPrice > 0 && (
                        <p className={`price ${hasDiscount ? ' line-through' : ''}`}>
                            {Formatter.price(retailPrice)}
                        </p>
                    )}
                    {hasDiscount && (
                        <>
                            <p className="price">{Formatter.price(salePrice)}</p>
                            <p className="price discount">{discountPrice}</p>
                        </>
                    )}
                </div>

                {item?.has_sizes_information ? (
                    <span>
                        {item?.color && (
                            <div className="color">
                                {texts.color}: <span>{item.color}</span>
                            </div>
                        )}

                        {sizes?.length && (
                            <div className="sizes">
                                <label>Aviliable Sizes: </label>
                                {sizes.join(',')}
                            </div>
                        )}
                    </span>
                ) : (
                    <p className="out-of-stock d-block">{texts.outOfStock}</p>
                )}

                {hasStylistNotes && (
                    <div className="why">
                        <p className="title">
                            {texts.stylist.replace('%stylist%', item.stylist_name)}
                        </p>
                        <p>{item.notes}</p>
                    </div>
                )}
            </div>
        </>
    );
};

export default ItemDetails;
