import React from 'react';

const GridIcon = ({
    setLayout,
    setCanvasLayout,
    openCanvas,
    counter,
    setCanvasSizeIndex,
    isMoodboard
}: {
    setLayout?: (wordCheck: string | undefined) => 'show' | 'hide' | 'on' | 'off';
    setCanvasLayout: (layout: string) => void;
    openCanvas?: (
        setCanvasSizeIndex: (size: number) => void,
        setCanvasLayout: (layout: string) => void,
        layout: string
    ) => void;
    counter?: {
        items: number;
        ps: number;
    };
    setCanvasSizeIndex: (size: number) => void;
    isMoodboard: boolean;
}) => (
    <>
        {!isMoodboard && (
            <div
                className={`layout-btn grid ${setLayout ? setLayout('grid') : ''}`}
                onClick={
                    openCanvas !== undefined
                        ? () => openCanvas(setCanvasSizeIndex, setCanvasLayout, 'grid')
                        : setCanvasLayout !== undefined
                        ? () => setCanvasLayout('grid')
                        : () => {}
                }
            >
                {counter && counter.ps > 0 && <div className="items-counter">{counter.ps}</div>}
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
            </div>
        )}
    </>
);
export default GridIcon;
