import { History } from 'history';

import { Config, Cookies, Tracking, User, UserAuth } from '.';
import { sentryException } from './SentryLogging';
import { getUserToken } from './utils/token-utils';

export interface ICredentials {
    email: string;
    password: string;
    remember: boolean;
    token?: string;
    facebook_token?: string;
    gender?: string;
    brands?: string;
    styles?: { val: { name: string; rate: number } };
    tags?: string;
}
const prepCredentials = (credentials: Partial<ICredentials>) => {
    const { email, password, remember, token } = credentials;
    let loginCredentials;

    if (token) {
        loginCredentials = { token };
    } else if (credentials.facebook_token) {
        loginCredentials = credentials;
    } else {
        loginCredentials = { email, password, remember };
    }
    return loginCredentials;
};
const login = async (credentials: Partial<ICredentials>) => {
    try {
        const loginCredentials = prepCredentials(credentials);
        const result = await UserAuth.login(loginCredentials);
        const uuid = result?.data.data ? result.data.data.uuid : result?.data.user_uuid;

        // *behind the scene there are different api calls made depending on the use case.
        // *and these calls return different data structure.
        const isStylist =
            result?.data?.type === 'stylist' || result?.data?.data?.type === 'stylist';

        if (!isStylist) {
            return {
                error: {
                    field: 'email',
                    message: 'User does not exist in the system',
                    showMessage: true
                }
            };
        }

        if (result?.data?.code !== 401) {
            // Temporary solution for old api, returns data object inside result data.
            // To be removed once new signInTokan api is available.
            const token = result?.data.token || getUserToken();
            const { data } = await User.get(uuid, token);

            localStorage.setItem(
                'user',
                JSON.stringify({
                    isAdmin: data.isAdmin || data.is_admin,
                    uuid: data.user_uuid,
                    token,
                    fb_user: data.fb_user
                })
            );
            Cookies.set(
                'user',
                {
                    isAdmin: data.isAdmin || data.is_admin,
                    uuid: data.user_uuid,
                    token,
                    fb_user: data.fb_user
                },
                {
                    expires: credentials.remember ? 365 : null,
                    path: '/'
                }
            );
            Tracking.tag({ event: 'ws.signIn', user_id: data.user_uuid });
            Tracking.track(
                { id: data.user_uuid, device: `${Config.isMobile() ? 'mobile-' : ''}web` },
                token
            );
            return {
                ...data,
                token
            };
        }
        sentryException(new Error('authentication failed'), 'authentication failed');
    } catch (err) {
        throw {
            message: (err as any).response.data.message,
            errors: (err as any).response.data.errors
        };
    }
};
const logout = (history?: History) => {
    localStorage.removeItem('user');
    Cookies.remove('user', { path: '/' });
    Cookies.remove('user', { path: '/app' });
    // TODO: pass history and run this one.
    if (history) {
        history.push('/');
    }
    return;
};

export default {
    login,
    logout
};
