import { sentryException } from 'services/SentryLogging';

import { Style, Stylist, User } from '../../../services';
import { formater } from '../../../services/utils/availability-utils';
import {
    addItemAttributes,
    budgetOptions,
    budgetStoreOptions,
    colorsMaps
} from '../../../services/utils/styleRoom-utils';
import { clearBookingsList, updateSelectedBookingId } from '../../../store/booking/actions';

export const STYLING_ROOM_LOAD_START = 'STYLING_ROOM_LOAD_START';
export const STYLING_ROOM_LOAD_FAILED = 'STYLING_ROOM_LOAD_FAILED';
export const SET_CANVAS_LOAD = 'SET_CANVAS_LOAD';
export const LOAD_FILTERS_START = 'LOAD_FILTERS_START';
export const LOAD_FILTERS_FAILED = 'LOAD_FILTERS_FAILED';
export const UPDATE_STYLE_REQUEST = 'UPDATE_STYLE_REQUEST';
export const UPDATE_CLOSET = 'UPDATE_CLOSET';
export const UPDATE_SHOP = 'UPDATE_SHOP';
export const UPDATE_AVAILABILITY = 'UPDATE_AVAILABILITY';
export const UPDATE_AVAILABILITY_FILLER = 'UPDATE_AVAILABILITY_FILLER';
export const UPDATE_STORE = 'UPDATE_STORE';
export const UPDATE_INSPIRATION = 'UPDATE_INSPIRATION';
export const UPDATE_BOARDS = 'UPDATE_BOARDS';
export const UPDATE_CLIENT_CART = 'UPDATE_CLIENT_CART';
export const UPDATE_SELECTED_FILTERS = 'UPDATE_SELECTED_FILTERS';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const UPDATE_GENDER = 'UPDATE_GENDER';
export const CLEAR_SELECTED_FILTERS = 'CLEAR_SELECTED_FILTERS';
export const SET_GRID_SIZE = 'SET_GRID_SIZE';
export const SET_CANVAS_SIZE_INDEX = 'SET_CANVAS_SIZE_INDEX';
export const CLEAR_CLOSET = 'CLEAR_CLOSET';
export const CLEAR_SHOP = 'CLEAR_SHOP';
export const CLEAR_STORE = 'CLEAR_STORE';
export const CLEAR_CANVAS = 'CLEAR_CANVAS';
export const CLEAR_BOARDS = 'CLEAR_BOARDS';
export const ADD_TO_CANVAS = 'ADD_TO_CANVAS';
export const REMOVE_FROM_CANVAS = 'REMOVE_FROM_CANVAS';
export const SET_CANVAS_LAYOUT = 'SET_CANVAS_LAYOUT';
export const UPDATE_CANVAS_STATE = 'UPDATE_CANVAS_STATE';
export const STYLING_ROOM_SAVE_SUCCESS = 'STYLING_ROOM_SAVE_SUCCESS';
export const UPDATE_CANVAS_DATA_URL = 'UPDATE_CANVAS_DATA_URL';
export const STYLING_ROOM_CLEAR_RESULT = 'STYLING_ROOM_CLEAR_RESULT';
export const REMOVE_FROM_STORE = 'REMOVE_FROM_STORE';
export const STYLING_ROOM_SET_REVISION = 'STYLING_ROOM_SET_REVISION';
export const STYLING_ROOM_SET_TYPE = 'STYLING_ROOM_SET_TYPE';
export const CATEGORIES_DATA_AND_SIZES = 'CATEGORIES_DATA_AND_SIZES';
export const UPDATE_CANVAS_TOTAL_PRICE = 'UPDATE_CANVAS_TOTAL_PRICE';

export const stylingRoomLoadStart = () => ({
    type: STYLING_ROOM_LOAD_START
});

export const loadFiltersStart = () => ({
    type: LOAD_FILTERS_START
});

export const setCanvasLoad = (loading) => ({
    type: SET_CANVAS_LOAD,
    payload: { loading }
});

export const stylingRoomLoadFailed = (error) => ({
    type: STYLING_ROOM_LOAD_FAILED,
    payload: { error }
});

export const loadFiltersFailed = (error) => ({
    type: LOAD_FILTERS_FAILED,
    payload: { error }
});

export const updateStyleRequest = (styleRequest) => ({
    type: UPDATE_STYLE_REQUEST,
    payload: { styleRequest }
});

export const updateCloset = ({ items, quota_max }) => ({
    type: UPDATE_CLOSET,
    payload: { closet: items, total: quota_max }
});

export const updateShop = ({ items, total_count, nextPage }) => ({
    type: UPDATE_SHOP,
    payload: { shop: items, total: total_count, nextPage: nextPage }
});

export const updateStore = ({ items, quota_max }) => ({
    type: UPDATE_STORE,
    payload: { store: items, total: quota_max }
});

export const updateInspiration = (inspiration) => ({
    type: UPDATE_INSPIRATION,
    payload: { inspiration }
});

export const updateBoards = (boards) => ({
    type: UPDATE_BOARDS,
    payload: { boards }
});

export const updateClientCart = ({ items, quota_max }) => ({
    type: UPDATE_CLIENT_CART,
    payload: { cart: items, total: quota_max }
});

export const updateFilters = (filters, type) => ({
    type: UPDATE_FILTERS,
    payload: { filters, type }
});

export const updateSelectedFilters = (selectedFilters) => ({
    type: UPDATE_SELECTED_FILTERS,
    payload: { selectedFilters }
});

export const updateGender = (gender) => ({
    type: UPDATE_GENDER,
    payload: { gender }
});

export const clearSelectedFilters = () => ({
    type: CLEAR_SELECTED_FILTERS
});

export const clearCloset = () => ({
    type: CLEAR_CLOSET
});

export const clearShop = () => ({
    type: CLEAR_SHOP
});
export const clearBoards = () => ({
    type: CLEAR_BOARDS
});
export const clearStore = () => ({
    type: CLEAR_STORE
});

export const clearCanvas = () => ({
    type: CLEAR_CANVAS
});

export const updateCanvasTotalPrice = () => ({
    type: UPDATE_CANVAS_TOTAL_PRICE
});

export const setGridSize = (gridSize) => ({
    type: SET_GRID_SIZE,
    payload: { gridSize }
});

export const setCanvasSizeIndex = (canvasSizeIndex) => ({
    type: SET_CANVAS_SIZE_INDEX,
    payload: { canvasSizeIndex }
});

export const setCanvasLayout = (canvasLayout) => ({
    type: SET_CANVAS_LAYOUT,
    payload: { canvasLayout }
});

export const addToCanvas = (item, type) => ({
    type: ADD_TO_CANVAS,
    payload: { item: item, type: type }
});

export const removeFromCanvas = (id) => ({
    type: REMOVE_FROM_CANVAS,
    payload: { id }
});

export const updateCanvasNfiltState = (canvasState) => ({
    type: UPDATE_CANVAS_STATE,
    payload: { canvasState }
});

export const updateCanvasState = (canvasState) => ({
    type: UPDATE_CANVAS_STATE,
    payload: { canvasState }
});

export const updateCanvasDataUrl = (canvasDataUrl) => ({
    type: UPDATE_CANVAS_DATA_URL,
    payload: { canvasDataUrl }
});

export const stylingRoomSaveSuccess = (result) => ({
    type: STYLING_ROOM_SAVE_SUCCESS,
    payload: { result }
});

export const stylingRoomClearResult = () => ({
    type: STYLING_ROOM_CLEAR_RESULT
});

export const setRevision = (revision) => ({
    type: STYLING_ROOM_SET_REVISION,
    payload: { revision }
});

export const saveCategoriesData = (data) => ({
    type: CATEGORIES_DATA_AND_SIZES,
    payload: data
});

export const loadStyleRequest = (request_uuid) => {
    return async (dispatch) => {
        dispatch(stylingRoomLoadStart());
        try {
            const { data } = await Style.getRequest(request_uuid);
            const styleData = await User.styleData(data.owner.user_uuid);
            dispatch(updateStyleRequest({ ...data, styleData: styleData.data }));
        } catch (error) {
            dispatch(stylingRoomLoadFailed(error));
        }
    };
};

export const loadShop = (params = {}) => {
    return async (dispatch, getState) => {
        dispatch(stylingRoomLoadStart());
        try {
            const {
                stylingRoom: { styleRequest, shop = [], gender },
                user: { user }
            } = getState();
            if (!params.page) params.page = 1;
            if (!params.count) params.count = 50;
            if (!params.userUuid)
                params.userUuid = styleRequest.owner
                    ? styleRequest.owner.user_uuid
                    : user.user_uuid;
            if (!params.gender) params.gender = gender;
            if (params.page === 1) dispatch(clearShop());
            const {
                data: { data, count, nextPage }
            } = await Stylist.getShopItems({
                ...params,
                count: 50
            });
            const items = data,
                total_count = count;
            dispatch(
                updateShop({
                    items: params.page === 1 ? items : [...shop, ...items],
                    total_count,
                    nextPage
                })
            );

            const isSizeFilter = params.size ? true : false;
            const itemsForCheck = formater(
                params.page === 1 ? items : [...shop, ...items],
                isSizeFilter
            );
            const shopStatus = params.page === 1 ? 'new' : 'loadMore';
            dispatch(updateItemsAvilability(itemsForCheck, shopStatus));
        } catch (error) {
            dispatch(stylingRoomLoadFailed(error));
        }
    };
};

const availbilityFiller = (itemsForFiller, isStockNeedsUpdate = false) => {
    return (dispatch) => {
        const fillerItems = {};
        const keys = Object.keys(itemsForFiller.items);
        if (isStockNeedsUpdate) {
            keys.forEach((key) => (fillerItems[key] = { init: 'loading' }));
        }
        dispatch({ type: UPDATE_AVAILABILITY_FILLER, payload: { fillerItems } });
    };
};

const getItemsData = async (items) => {
    const response = await Stylist.getAvailability(items);
    return response.data;
};

const updateItemsAvilability = (items, shopStatus) => {
    const itemsList = items.items;
    return async (dispatch, getState) => {
        const itemsForUpdate = { items: {} };
        const itemsWithoutUpdate = { items: {} };
        const {
            stylingRoom: { retailerStockForUpdate }
        } = getState();
        const keys = Object.keys(itemsList);
        keys.forEach((key) => {
            const item = itemsList[key];
            if (
                retailerStockForUpdate &&
                retailerStockForUpdate.includes(item.brand.toLowerCase())
            ) {
                itemsForUpdate['items'][key] = item;
            } else {
                itemsWithoutUpdate['items'][key] = item;
            }
        });
        if (itemsForUpdate && Object.keys(itemsForUpdate.items).length) {
            if (shopStatus == 'new') {
                dispatch(availbilityFiller(itemsForUpdate, true));
            }
            const itemsAvailability = await getItemsData(itemsForUpdate);
            const {
                stylingRoom: { selectedFilters }
            } = getState();

            const isSelectedRetailer =
                selectedFilters && selectedFilters.retailers && selectedFilters.retailers.length;

            if (isSelectedRetailer) {
                const filteredRetailers = selectedFilters.retailers.filter((retailer) =>
                    retailerStockForUpdate.includes(retailer.text.toLowerCase())
                );
                if (filteredRetailers.length)
                    dispatch({ type: UPDATE_AVAILABILITY, payload: { itemsAvailability } });
            } else dispatch({ type: UPDATE_AVAILABILITY, payload: { itemsAvailability } });
        }
        if (itemsWithoutUpdate && Object.keys(itemsWithoutUpdate.items).length) {
            dispatch(availbilityFiller(itemsWithoutUpdate));
        }
    };
};

export const loadCloset = (params = {}) => {
    return async (dispatch, getState) => {
        dispatch(stylingRoomLoadStart());
        try {
            const {
                stylingRoom: { styleRequest, closet = [] },
                user: { user }
            } = getState();
            if (!params.from) params.from = 0;
            if (!params.count) params.count = 50;
            if (params.from === 0) dispatch(clearCloset());
            const {
                data: { items, quota_max }
            } = await User.closet({
                user_uuid: styleRequest.owner ? styleRequest.owner.user_uuid : user.user_uuid,
                params
            });

            dispatch(
                updateCloset({
                    items: params.from === 0 ? items : [...closet, ...items],
                    quota_max
                })
            );
        } catch (error) {
            dispatch(stylingRoomLoadFailed(error));
        }
    };
};

export const loadStore = (params = {}) => {
    return async (dispatch, getState) => {
        dispatch(stylingRoomLoadStart());
        try {
            const {
                stylingRoom: { styleRequest, store = [], gender },
                user: { user }
            } = getState();
            if (!params.from) params.from = 0;
            if (!params.count) params.count = 50;
            if (params.from === 0) dispatch(clearStore());
            if (!params.gender)
                params.gender = styleRequest.owner ? styleRequest.owner.gender : gender;
            const {
                data: { items, quota_max }
            } = await Stylist.store(
                styleRequest.stylist ? styleRequest.stylist.user_uuid : user.user_uuid,
                params
            );

            dispatch(
                updateStore({
                    items: params.from === 0 ? items : [...store, ...items],
                    quota_max
                })
            );
        } catch (error) {
            dispatch(stylingRoomLoadFailed(error));
        }
    };
};

export const loadInspiration = (params = {}) => {
    return async (dispatch, getState) => {
        dispatch(stylingRoomLoadStart());
        const {
            stylingRoom: { gender, inspiration }
        } = getState();
        try {
            if (!params.from) params.from = 0;
            if (!params.count) params.count = 50;
            if (!params.is_trend) params.is_trend = 1;
            if (!params.gender) params.gender = gender;
            const { data } = await Stylist.inspiration(params);

            dispatch(updateInspiration(params.from === 0 ? data : [...inspiration, ...data]));
        } catch (error) {
            dispatch(updateInspiration(inspiration));
            dispatch(stylingRoomLoadFailed(error));
        }
    };
};

export const loadBoards = (params = {}) => {
    return async (dispatch, getState) => {
        dispatch(stylingRoomLoadStart());
        try {
            const {
                stylingRoom: { styleRequest, gender },
                stylists: { looks },
                user: { user }
            } = getState();
            if (!params.from) params.from = 1;
            if (!params.count) params.count = 50;
            if (!params.gender) params.gender = gender;
            if (params.from === 1) dispatch(clearBoards());
            let {
                data: { outfits }
            } = await Stylist.looks(
                styleRequest.stylist ? styleRequest.stylist.user_uuid : user.user_uuid,
                {
                    ...params,
                    from: looks.length > 0 ? looks.length : 1,
                    user_uuid: styleRequest.owner ? styleRequest.owner.user_uuid : user.user_uuid,
                    type: params.type || 'look'
                }
            );

            dispatch(updateBoards(outfits.data));
        } catch (error) {
            dispatch(stylingRoomLoadFailed(error));
        }
    };
};

export const loadClientCart = (user_uuid, params = {}) => {
    return async (dispatch, getState) => {
        dispatch(stylingRoomLoadStart());
        try {
            const {
                stylingRoom: { cart }
            } = getState();
            if (!params.from) params.from = 1;
            if (!params.count) params.count = 30;
            const {
                data: { items, quota_max }
            } = await User.cart({ user_uuid, params });

            dispatch(
                updateClientCart({
                    items: params.from === 1 ? items : [...cart, ...items],
                    quota_max
                })
            );
        } catch (e) {
            dispatch(stylingRoomLoadFailed(e));
        }
    };
};

export const loadShopFilters = (params = {}) => {
    return async (dispatch, getState) => {
        dispatch(loadFiltersStart());
        try {
            const {
                stylingRoom: { gender, selectedFilters }
            } = getState();

            const { data } = await Stylist.shopFiltersNew(params);
            const { categoryFilters, globalFilters } = data;
            const filtersByGender = categoryFilters[gender];
            const categoriesByGender = filtersByGender.Categories;
            dispatch(saveCategoriesData(categoriesByGender));

            const hasCategory = selectedFilters?.category?.length > 0;
            const category = hasCategory ? selectedFilters?.category[0].key : 'Shirts';
            const retailersByCategoryGender = addItemAttributes(
                filtersByGender.Retailers.categories[category],
                false
            );

            const filtersStruct = {
                brand: { key: 'brand', value: 'Retailers', filter_options: [] },
                category: { key: 'category', value: 'Categories', filter_options: [] },
                color: { key: 'color', value: 'Colors', filter_options: [] },
                budget: { key: 'budget', value: 'Budget', filter_options: budgetOptions },
                itemBrand: { key: 'itemBrand', value: 'Designers', filter_options: [] },
                size: { key: 'size', value: 'Sizes', filter_options: [] }
            };

            if (!params.client) params.client = 'web';
            if (!params.gender) params.gender = gender;

            // Retailers
            filtersStruct.brand.filter_options = retailersByCategoryGender;

            // Categories
            const categoryArray = addItemAttributes(categoriesByGender, true);
            filtersStruct.category.filter_options = categoryArray;

            // Colors
            const colorObjIndex = globalFilters.findIndex((filter) => filter.key === 'color');
            const colorsObj = colorsMaps(globalFilters[colorObjIndex].values);
            filtersStruct.color.filter_options = colorsObj;

            // Designers
            if (selectedFilters && selectedFilters.category) {
                const category = selectedFilters.category[0].key;
                try {
                    const designersArray = addItemAttributes(
                        filtersByGender.Brands.categories[category]
                    );
                    filtersStruct.itemBrand.filter_options = designersArray;
                } catch (e) {
                    delete filtersStruct.itemBrand.filter_options;
                }

                // Sizes
                try {
                    const sizesArray = addItemAttributes(
                        filtersByGender.Sizes.categories[category],
                        true
                    );
                    filtersStruct.size.filter_options = sizesArray;
                } catch (e) {
                    delete filtersStruct.size.filter_options;
                }
            }

            if (!hasCategory) {
                const categoryOptions = filtersStruct.category.filter_options;
                if (categoryOptions.length)
                    dispatch(
                        updateStylingRoomFilters(
                            { key: 'category', value: categoryOptions[0] },
                            false
                        )
                    );

                dispatch(loadShopFilters({ ...params, category: categoryOptions[0].key }));
            } else {
                dispatch(updateFilters(Object.values(filtersStruct), 'shop'));
            }
        } catch (error) {
            sentryException(error, "Couldn't load shop filters");
            dispatch(loadFiltersFailed(error));
        }
    };
};

export const loadClosetFilters = (params = {}) => {
    return async (dispatch, getState) => {
        dispatch(loadFiltersStart());
        try {
            const {
                stylingRoom: { styleRequest, gender, selectedFilters },
                user: { user }
            } = getState();
            if (!params.client) params.client = 'web';
            if (!params.gender) params.gender = gender;
            params.refinements = 'USER_SPECIFIC';
            const { data } = await User.closetFilters({
                user_uuid: styleRequest.owner ? styleRequest.owner.user_uuid : user.user_uuid,
                params
            });

            if (!selectedFilters.category) {
                const categoryOptions = data.find(
                    (filter) => filter.key === 'category'
                ).filter_options;
                if (categoryOptions.length)
                    dispatch(
                        updateStylingRoomFilters(
                            { key: 'category', value: categoryOptions[0] },
                            false
                        )
                    );
                dispatch(updateFilters(data, 'closet'));
                dispatch(loadClosetFilters({ ...params, category: categoryOptions[0].key }));
            } else {
                dispatch(updateFilters(data, 'closet'));
            }
        } catch (error) {
            dispatch(loadFiltersFailed(error));
        }
    };
};

export const loadStoreFilters = (params = {}) => {
    return async (dispatch, getState) => {
        dispatch(loadFiltersStart());
        try {
            const {
                stylingRoom: { styleRequest, selectedFilters, gender },
                user: { user }
            } = getState();
            if (!params.client) params.client = 'web';
            if (!params.gender) params.gender = gender;

            const { data } = await Stylist.storeFilters(
                styleRequest.stylist ? styleRequest.stylist.user_uuid : user.user_uuid,
                params
            );

            const categoriesIndex = data.findIndex(
                (filter) => filter.key.toLowerCase() === 'category'
            );

            const priceIndex = data.findIndex((filter) => filter.key === 'PRICES');

            data[priceIndex].key = 'budget';
            data[priceIndex].filter_options = budgetStoreOptions;
            data[categoriesIndex].filter_options.map((retailer) => (retailer.single_select = true));

            if (!selectedFilters.category) {
                const categoryOptions = data[categoriesIndex].filter_options;
                if (categoryOptions.length)
                    dispatch(
                        updateStylingRoomFilters(
                            { key: 'category', value: categoryOptions[0] },
                            false
                        )
                    );
                dispatch(updateFilters(data, 'store'));
            } else {
                dispatch(updateFilters(data, 'store'));
            }
        } catch (error) {
            dispatch(loadFiltersFailed(error));
        }
    };
};

export const loadBoardsFilters = (params = {}) => {
    return async (dispatch, getState) => {
        dispatch(loadFiltersStart());
        try {
            const {
                stylingRoom: { gender }
            } = getState();
            const { data } = await Style.tags(params.gender ? params.gender : gender, 'style');

            dispatch(
                updateFilters(
                    [
                        {
                            key: 'style_tags',
                            value: 'Style',
                            filter_options: data.map((filter) => ({
                                key: filter.name,
                                text: filter.name
                            }))
                        }
                    ],
                    'boards'
                )
            );
        } catch (error) {
            dispatch(loadFiltersFailed(error));
        }
    };
};

export const loadOrdersFilters = (params = {}) => {
    return async (dispatch, getState) => {
        dispatch(loadFiltersStart());
        try {
            const {
                stylingRoom: { styleRequest, selectedFilters }
            } = getState();
            const { data } = await User.ordersFilters(styleRequest.owner.user_uuid, params);

            if (!selectedFilters.category) {
                const categoryOptions = data.find(
                    (filter) => filter.key === 'category'
                ).filter_options;
                if (categoryOptions.length)
                    dispatch(
                        updateStylingRoomFilters(
                            { key: 'category', value: categoryOptions[0] },
                            false
                        )
                    );
                dispatch(loadOrdersFilters({ ...params, category: categoryOptions[0].key }));
            } else {
                dispatch(updateFilters(data, 'orders'));
            }
        } catch (error) {
            dispatch(loadFiltersFailed(error));
        }
    };
};

export const loadInspirationTags = (params = {}) => {
    return async (dispatch, getState) => {
        dispatch(loadFiltersStart());
        try {
            const {
                stylingRoom: { gender }
            } = getState();
            if (!params.from) params.from = 0;
            if (!params.count) params.count = 10000;
            if (!params.gender) params.gender = gender;
            const { data } = await Stylist.inspirationTags(params);

            dispatch(updateFilters(data, 'inspiration'));
        } catch (error) {
            dispatch(loadFiltersFailed(error));
        }
    };
};

export const updateStylingRoomFilters = (update = {}, remove = false, callback) => {
    return async (dispatch, getState) => {
        let SINGLE_SELECTION = ['category'];
        if (update.value.single_select) SINGLE_SELECTION.push(update.key);

        const {
            stylingRoom: { selectedFilters, filters }
        } = getState();
        let _filters = { ...selectedFilters };
        if (remove) {
            if (update.key === 'budget' && Array.isArray(update.value)) {
                if (Array.isArray(update.value)) {
                    delete _filters[update.key];
                    update.value.forEach(({ key, value, text }) => {
                        _filters[key] = [{ key: value, text }];
                    });
                }
                delete _filters.maxPrice;
                delete _filters.minPrice;
                delete _filters.min_budget;
                delete _filters.max_budget;
            } else {
                _filters[update.key] = _filters[update.key].filter(
                    (filter) => filter.key !== update.value.key
                );
            }
        } else {
            if (!_filters[update.key]) _filters[update.key] = [];
            if (_filters[update.key].find((item) => item.key === update.key)) {
                _filters[update.key] = _filters[update.key].filter(
                    (filter) => filter.key !== update.key
                );
            } else {
                const NO_CATEGORIES = ['category', 'style_tags', 'keywords'];
                if (!_filters.category && !NO_CATEGORIES.includes(update.key) && filters.length) {
                    const category = filters.find((filter) => filter.key === 'category');
                    if (category?.filter_options?.length)
                        _filters.category = [category.filter_options[0]];
                }
                if (update.key === 'category') delete _filters.sizes;
                if (Array.isArray(update.value)) {
                    delete _filters[update.key];
                    update.value.forEach(({ key, value, text }) => {
                        _filters[key] = [{ key: value, text }];
                    });
                } else {
                    if (SINGLE_SELECTION.includes(update.key)) {
                        _filters[update.key] = [update.value];
                    } else {
                        _filters[update.key] = _filters[update.key].filter(
                            (filter) => !filter.single_select
                        );
                        _filters[update.key].push(update.value);
                    }
                }
            }
        }
        dispatch(clearShop());
        dispatch(clearCloset());
        dispatch(clearStore());
        dispatch(clearBoards());
        dispatch(updateSelectedFilters(_filters));
        if (callback) callback(_filters);
    };
};
const deleteSavedCanvas = (canvasName) => {
    const localStorageKeys = Object.keys(localStorage);
    localStorageKeys.map((key) => {
        if (key.includes(canvasName)) delete localStorage[key];
    });
};

export const saveMoodboard = (info) => {
    return async (dispatch, getState) => {
        dispatch(stylingRoomLoadStart());
        try {
            const {
                stylingRoom: { styleRequest, items, gender, canvasDataUrl },
                user: { user }
            } = getState();
            const owner_uuid = styleRequest.stylist
                ? styleRequest.stylist.user_uuid
                : user.user_uuid;
            const target_uuid = styleRequest.owner ? styleRequest.owner.user_uuid : user.user_uuid;
            if (owner_uuid == target_uuid) {
                const params = {
                    data: {
                        ...info,
                        type: 'outfit',
                        gender: 'female',
                        is_moodboard: true,
                        items: items.filter((item) => !!item),
                        owner_uuid: owner_uuid,
                        style_request_uuid: styleRequest.uuid,
                        target_uuid: target_uuid,
                        publish_on_profile: false
                    }
                };
                params.data.picture = canvasDataUrl.replace('data:image/png;base64,', '');
                const { data } = await Stylist['look'](params);
                updateSelectedBookingId(dispatch, null);
                clearBookingsList(dispatch);
                dispatch(stylingRoomSaveSuccess(data));
            } else {
                const { data } = await Stylist.moodboard({
                    description: info.description,
                    tags: info.tags,
                    items: items.filter((item) => !!item),
                    picture: canvasDataUrl.replace('data:image/png;base64,', ''),
                    type: 'outfit',
                    owner_uuid: owner_uuid,
                    style_request_uuid: styleRequest.uuid || '',
                    target_uuid: target_uuid,
                    publish_on_feed: info.publish_on_feed ? info.publish_on_feed : false,
                    gender
                });
                updateSelectedBookingId(dispatch, null);
                clearBookingsList(dispatch);
                deleteSavedCanvas(''.concat(owner_uuid, target_uuid));
                dispatch(stylingRoomSaveSuccess(data));
            }
        } catch (e) {
            dispatch(stylingRoomLoadFailed(e));
        }
    };
};

export const saveLook = (info, type = 'outfit', outfit_uuid = null) => {
    return async (dispatch, getState) => {
        dispatch(stylingRoomLoadStart());
        try {
            const {
                stylingRoom: { styleRequest, items, ps, canvasLayout, gender, canvasDataUrl },
                user: { user }
            } = getState();
            const _items = canvasLayout === 'grid' ? ps : items;
            const params = {
                data: {
                    ...info,
                    type,
                    gender,
                    items: _items.filter((item) => !!item),
                    owner_uuid: styleRequest.stylist
                        ? styleRequest.stylist.user_uuid
                        : user.user_uuid,
                    style_request_uuid: styleRequest.uuid,
                    target_uuid: styleRequest.owner ? styleRequest.owner.user_uuid : user.user_uuid,
                    publish_on_profile: false
                }
            };
            if (type === 'outfit')
                params.data.picture = canvasDataUrl.replace('data:image/png;base64,', '');
            if (outfit_uuid) params.outfit_uuid = outfit_uuid;
            const { data } = await Stylist[outfit_uuid ? 'revision' : 'look'](params);
            updateSelectedBookingId(dispatch, null);
            clearBookingsList(dispatch);
            deleteSavedCanvas(
                ''.concat(styleRequest.stylist.user_uuid, styleRequest.owner.user_uuid)
            );
            dispatch(stylingRoomSaveSuccess(data));
        } catch (e) {
            dispatch(stylingRoomLoadFailed(e));
        }
    };
};

export const removeStoreItem = (item) => {
    return async (dispatch, getState) => {
        dispatch(stylingRoomLoadStart());
        try {
            const {
                stylingRoom: { styleRequest, store, total },
                user: { user }
            } = getState();
            await Stylist.storeRemove(
                styleRequest.stylist ? styleRequest.stylist.user_uuid : user.user_uuid,
                [item.uuid]
            );
            dispatch(
                updateStore({
                    items: store.filter(({ uuid }) => uuid !== item.uuid),
                    total: total - 1
                })
            );
        } catch (e) {
            dispatch(stylingRoomLoadFailed(e));
        }
    };
};
