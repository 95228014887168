import { useReducer } from 'hooks';
import bookingReducer from 'store/booking/reducer';
import { name as bookingName } from 'store/booking/reducer';
import commonReducer from 'store/common/reducer';
import { name as commonName } from 'store/common/reducer';
import errorReducer from 'store/error/reducer';
import { name as errorName } from 'store/error/reducer';
import paymentsReducer from 'store/payments/reducer';
import { name as paymentsName } from 'store/payments/reducer';
import roomReducer from 'store/room/reducer';
import { name as roomName } from 'store/room/reducer';
import servicesReducer from 'store/services/reducer';
import { name as servicesName } from 'store/services/reducer';
import uiReducer from 'store/ui/reducer';
import { name as uiName } from 'store/ui/reducer';
import userReducer from 'store/user/reducer';
import { name as userName } from 'store/user/reducer';

export const useInitCommonReducer = () => {
    useReducer(commonName, commonReducer);
    useReducer(userName, userReducer);
    useReducer(bookingName, bookingReducer);
    useReducer(errorName, errorReducer);
    useReducer(paymentsName, paymentsReducer);
    useReducer(uiName, uiReducer);
    useReducer(servicesName, servicesReducer);
    useReducer(roomName, roomReducer);
};
