import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toggleModal } from 'store/ui/actions';

import { loadEvents, loadTags } from '../../../redux/reducers/style/actions';
import { saveLook, stylingRoomClearResult } from '../../../redux/reducers/stylingRoom/actions';
import SaveStyleboard from './SaveStyleboard';

const mapStateToProps = ({
    ui: { redirectUrl },
    style: { loading, tags, events },
    stylingRoom: { loading: stylingRoomLoading, result, gender, styleRequest },
    room: { revision }
}) => ({
    loading,
    stylingRoomLoading,
    styleRequest,
    tags,
    events,
    result,
    gender,
    revision,
    redirectUrl
});

const mapDispatchToProps = (dispatch) => ({
    loadTags: (gender, type) => dispatch(loadTags(gender, type)),
    loadEvents: () => dispatch(loadEvents()),
    saveLook: (info, type, revision) => dispatch(saveLook(info, type, revision)),
    clearResult: () => dispatch(stylingRoomClearResult()),
    toggleModal: (data) => toggleModal(dispatch, data)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SaveStyleboard));
