import './style.scss';

import React, { useEffect, useRef, useState } from 'react';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';
import Formatter from 'services/Formatter';
import {
    ClosetItem,
    ClosetQueryParameters,
    ClosetType,
    FilterOptionsParameters,
    FilterParams,
    FilterStruct,
    Item,
    Order,
    styleRequest
} from 'types/styling';
import { Client } from 'types/user';

import { Filters, GridItem, Loader } from '../../../components';
import { styling } from '../../../content.json';
import Empty from './Empty';

interface ICloset {
    user: Client;
    loading: boolean;
    loadingFilters: boolean;
    loadCloset: (input: ClosetQueryParameters) => void;
    closet: ClosetItem[];
    loadClosetFilters: (fields: Record<string, string>) => void;
    loadCartFilters: (user_uuid: string | undefined) => void;
    updateFilters: (filter: FilterParams, isSelected: boolean, callback: () => void) => void;
    clearSelectedFilters: () => void;
    cartFilters: FilterStruct[];
    filters: FilterStruct[];
    filtersType: string;
    selectedFilters: Record<string, FilterOptionsParameters[]>;
    setGridSize: (input: string) => void;
    gridSize: string;
    clearCloset: () => void;
    total: number;
    addToCanvas: (item: ClosetItem | Order) => void;
    styleRequest: styleRequest;
    loadClientCart: (user_uuid: string | undefined, input: ClosetQueryParameters) => void;
    cart: ClosetItem[];
    loadOrders: (input: ClosetQueryParameters) => void;
    loadOrdersFilters: (fields: Record<string, string>) => void;
    orders: Order[];
    ordersTotal: number;
}

const Closet: React.FC<ICloset> = ({
    user,
    loading,
    loadingFilters,
    loadCloset,
    closet,
    loadClosetFilters,
    loadCartFilters,
    updateFilters,
    clearSelectedFilters,
    cartFilters,
    filters,
    filtersType,
    selectedFilters,
    setGridSize,
    gridSize,
    clearCloset,
    total,
    addToCanvas,
    styleRequest,
    loadClientCart,
    cart,
    loadOrders,
    loadOrdersFilters,
    orders,
    ordersTotal
}) => {
    const history = useHistory();
    const [gridItems, setGridItems] = useState<ClosetItem[] | Order[]>([]);
    const [subcategory, setSubcategory] = useState<ClosetType>('closet');
    const resultsRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        clearCloset();
        clearSelectedFilters();
        setGridItems([]);
        switch (subcategory) {
            case 'cart':
                loadCartFilters(styleRequest.owner ? styleRequest.owner.user_uuid : user.user_uuid);
                break;
            case 'purchases':
                loadOrdersFilters({
                    fields: 'categories,colors,retailers,prices,brands,sizes'
                });
                break;
            case 'closet':
                loadClosetFilters({
                    fields: 'categories,colors,retailers,prices,brands,sizes'
                });
                break;
        }
    }, [subcategory]);

    useEffect(() => {
        if (
            filters.length &&
            (filtersType === 'closet' || filtersType === 'purchases' || filtersType === 'orders')
        )
            fetchData();
    }, [filters]);

    useEffect(() => {
        if (cartFilters.length) fetchData();
    }, [cartFilters]);

    useEffect(() => {
        if (!loading && closet) setGridItems(closet);
    }, [closet]);

    useEffect(() => {
        if (!loading && cart) setGridItems(cart);
    }, [cart]);

    useEffect(() => {
        if (!loading && orders) setGridItems(orders);
    }, [orders]);

    const onFilterChange = (filter: FilterParams) => {
        const isSelected =
            selectedFilters[filter.key] &&
            selectedFilters[filter.key].findIndex((item: FilterParams | FilterOptionsParameters) =>
                !Array.isArray(filter.value) ? item.key === filter.value.key : false
            ) > -1;
        updateFilters(filter, isSelected, fetchData);
    };

    const fetchData = (filters?: Record<string, FilterOptionsParameters[]>) => {
        const ITEMS_LIST = {
            cart: cart,
            purchases: orders,
            closet: closet
        };
        const activeFilters = filters ? filters : selectedFilters;
        if (filters && ITEMS_LIST[subcategory]) setGridItems([]);

        let params = {};
        switch (subcategory) {
            case 'cart':
                params = Object.keys(activeFilters).reduce(
                    (ac, a) =>
                        activeFilters[a].length
                            ? {
                                  ...ac,
                                  [a]: activeFilters[a].map((selection) => selection.key).join(',')
                              }
                            : ac,
                    {}
                );
                loadClientCart(styleRequest.owner ? styleRequest.owner.user_uuid : user.user_uuid, {
                    ...params,
                    from: !filters && cart ? cart.length : 1
                });
                break;
            case 'purchases':
                params = Object.keys(activeFilters).reduce(
                    (ac, a) =>
                        activeFilters[a].length
                            ? {
                                  ...ac,
                                  [a]: activeFilters[a].map((selection) => selection.key).join(',')
                              }
                            : ac,
                    {}
                );
                loadOrders({
                    ...params,
                    user_uuid: styleRequest.owner ? styleRequest.owner.user_uuid : user.user_uuid,
                    from: !filters && orders ? orders.length : 1
                });
                break;
            case 'closet':
                params = Object.keys(activeFilters).reduce(
                    (ac, a) =>
                        activeFilters[a].length
                            ? {
                                  ...ac,
                                  [a]: activeFilters[a].map((selection) => selection.key).join(',')
                              }
                            : ac,
                    {}
                );
                loadCloset({ ...params, from: !filters && closet ? closet.length : 0 });
                break;
        }
    };

    const getItemUuid = (itemObject: Item) => {
        const item = itemObject?.items?.length ? itemObject.items[0] : itemObject;
        return item.uuid || item.item_uuid;
    };

    return (
        <Container className="styling-room-closet" fluid>
            <Row>
                <Col className="filters-column">
                    {loadingFilters && <Loader />}
                    <p className="total">
                        {styling.items.replace(
                            '%count%',
                            Formatter.number(subcategory === 'purchases' ? ordersTotal : total)
                        )}
                    </p>
                    <Filters
                        className={
                            selectedFilters.category && !selectedFilters.category.length
                                ? 'categories-all'
                                : ''
                        }
                        filters={subcategory === 'cart' ? cartFilters : filters}
                        selected={selectedFilters}
                        setFilter={onFilterChange}
                        showSelection={true}
                    />
                </Col>
                <div id="results" className="results col" ref={resultsRef}>
                    {loading && <Loader />}
                    <div className="results-bar">
                        <div className="subcategories">
                            {styling.subcategories.closet.map((category) => (
                                <p
                                    key={category.value}
                                    className={`closet-${category.value} ${
                                        category.value === subcategory ? 'selected' : ''
                                    }`}
                                    onClick={() => {
                                        if (category.value !== subcategory) {
                                            clearSelectedFilters();
                                            setSubcategory(category.value as ClosetType);
                                        }
                                    }}
                                >
                                    {category.text.replace(
                                        '%client%',
                                        styleRequest.owner && styleRequest.owner.first_name
                                            ? styleRequest.owner.first_name
                                            : 'My'
                                    )}
                                </p>
                            ))}
                        </div>
                        <div className="filter-badges">
                            {Object.keys(selectedFilters).map((key) =>
                                selectedFilters[key].map((filter) => (
                                    <Badge variant="light" key={filter.key}>
                                        <div
                                            className="close"
                                            onClick={() => onFilterChange({ key, value: filter })}
                                        />
                                        {filter.text}
                                    </Badge>
                                ))
                            )}
                        </div>
                        <div className="grid-size">
                            <div
                                className={`size-btn large ${gridSize === 'large' ? 'active' : ''}`}
                                onClick={() => setGridSize('large')}
                            />
                            <div
                                className={`size-btn small ${gridSize === 'small' ? 'active' : ''}`}
                                onClick={() => setGridSize('small')}
                            />
                        </div>
                    </div>
                    {gridItems ? (
                        gridItems.length ? (
                            <InfiniteScroll
                                dataLength={gridItems ? gridItems.length : 0}
                                next={fetchData}
                                hasMore={true}
                                scrollableTarget="results"
                                loader={undefined}
                            >
                                {gridItems.map((item) => (
                                    <GridItem
                                        key={item.order_number || item.uuid}
                                        type="closet"
                                        size={gridSize}
                                        item={item}
                                        onImageClick={() => addToCanvas(item)}
                                        onDetailsClick={() =>
                                            history.push(`/item/${getItemUuid(item)}`)
                                        }
                                    >
                                        {}
                                    </GridItem>
                                ))}
                            </InfiniteScroll>
                        ) : (
                            <Empty
                                section={subcategory}
                                name={styleRequest.owner ? styleRequest.owner.first_name : 'Your'}
                            />
                        )
                    ) : (
                        ''
                    )}
                </div>
            </Row>
        </Container>
    );
};

export default Closet;
