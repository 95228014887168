import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { ItemType } from 'types/item';

import { item as texts } from '../../../content.json';
import { Formatter } from '../../../services';

interface IMoreItems {
    items: ItemType[];
    onClick: (item: ItemType) => void;
}

const MoreItems: React.FC<IMoreItems> = ({ items, onClick }) => (
    <Row className="more-items">
        <Col>
            <div className="title">{texts.more}</div>
            <div className="items">
                {items.map((item) => (
                    <div key={item.uuid || item.unique} className="item">
                        <span
                            onClick={() =>
                                item.unique &&
                                (item.item_type === 'shop' || item.item_type === 'catalog')
                                    ? onClick(item)
                                    : ''
                            }
                        >
                            <Image src={item.picture} />
                            <p className="brand">{item.brand_name}</p>
                            <p className="price">
                                {item.price && parseInt(item.price) > 0
                                    ? Formatter.price(item.price)
                                    : ''}
                            </p>
                        </span>
                    </div>
                ))}
            </div>
        </Col>
    </Row>
);

export default MoreItems;
