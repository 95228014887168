import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toggleModal } from 'store/ui/actions';

import { saveLook, stylingRoomClearResult } from '../../../redux/reducers/stylingRoom/actions';
import SavePersonalShopping from './SavePersonalShopping';

const mapStateToProps = ({
    ui: { redirectUrl },
    style: { loading },
    stylingRoom: { loading: stylingRoomLoading, result, gender, styleRequest }
}) => ({
    loading,
    stylingRoomLoading,
    styleRequest,
    result,
    gender,
    redirectUrl
});

const mapDispatchToProps = (dispatch) => ({
    saveLook: (info) => dispatch(saveLook(info, 'items')),
    clearResult: () => dispatch(stylingRoomClearResult()),
    toggleModal: (data) => toggleModal(dispatch, data)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SavePersonalShopping));
