export const STYLE_VALUES = [
    'gender',
    'body_tags',
    'style_icons',
    'goals_new',
    'events',
    'body_issues',
    'work_type',
    'holiday_type',
    'dress_size',
    'clothing_categories',
    'brands',
    'highlight',
    'colors_and_prints_objects',
    'clothing_preferences',
    'fit_top',
    'fit_bottom',
    'jeans',
    'fabrics',
    'budget_ranges',
    'comfort_zone',
    'heels',
    'jewelry',
    'what_matters'
];
export interface StylesOnly {
    budget_ranges: {
        name: string;
        ranges: {
            budget_min: string;
            budget_max: string;
        };
        categories: {
            uuid: string;
            name: string;
        };
    }[];
    work_type: string;
    style_icons: string[];
    gender: string;
    body_tags: {
        name: string;
        uuid: string;
        type: string;
    }[];
    goals_new: string[];
    holiday_type: string;
    dress_size: string;
    clothing_categories: {
        key: string;
        value: string;
        uuid: string;
    }[];
    brands: {
        name: string;
        uuid: string;
    }[];
    highlight: {
        key: string;
        value: string;
    }[];
    colors_and_prints_objects: {
        key: string;
        name: string;
        picture: string;
    }[];
    clothing_preferences: string;
    fit_top: string[];
    fit_bottom: string[];
    jeans: string[];
    fabrics: string[];
    comfort_zone: string;
    what_matters: string;
    jewelry: string[];
    heels: string;
    body_issues: {
        key: string;
        value: string;
    }[];
    body_issues_details: string;
    body_issues_other: string;
}
