import { Loader } from 'components';
import React from 'react';
import { Button, Col, Container, ListGroup, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Cookies, User } from 'services';
import { sentryException } from 'services/SentryLogging';
import { QuizResponse } from 'types/styles/user';
import { Client } from 'types/user';

import { ImageWithButton } from './ImageWithButton';

interface IQuiz {
    client: Client;
    uuid: string;
}
export const Quiz: React.FC<IQuiz> = ({ uuid, client }) => {
    const fetchResponses = async () => {
        try {
            const user = Cookies.get('user');
            const res = await User.quizResponses({
                user_uuid: uuid,
                token: user.token
            });
            const categorizedResponses: QuizResponse[] = [];
            const uncategorizedResponses: QuizResponse[] = [];
            (res?.data?.responses ?? []).forEach((response: QuizResponse) =>
                response.isCategorized
                    ? categorizedResponses.push(response)
                    : uncategorizedResponses.push(response)
            );
            return { categorizedResponses, uncategorizedResponses };
        } catch (error) {
            sentryException(error as Error, "Couldn't fetch quiz response");
            return null;
        }
    };
    const { data, isFetching } = useQuery<{
        categorizedResponses: QuizResponse[];
        uncategorizedResponses: QuizResponse[];
    } | null>(['quiz-responses', uuid, client.request_uuid], fetchResponses);

    const [showCategorizedResponses, setShowCategorizedResponses] = React.useState(false);

    const renderResponses = (responses: QuizResponse[]) => {
        return responses.map((response: QuizResponse) => (
            <ListGroup.Item key={response.id}>
                <Container fluid>
                    <Row>
                        <Col>
                            <span>
                                <b>{response.question.title}</b>
                            </span>
                        </Col>
                    </Row>
                    {response.question.subtitle && (
                        <Row>
                            <Col>
                                <span>
                                    <b>{response.question.subtitle}</b>
                                </span>
                            </Col>
                        </Row>
                    )}
                    {response.question.imageUrl && (
                        <ImageWithButton
                            imageUrl={response.question.imageUrl}
                            imageDescription={response.question.imageDescription}
                        />
                    )}
                    {response.answers.map((answer) => (
                        <div key={answer.key}>
                            {answer.text != null && (
                                <Row>
                                    <Col>
                                        <span>{answer.text}</span>
                                    </Col>
                                </Row>
                            )}
                            {answer.imageUrl != null && (
                                <ImageWithButton imageUrl={answer.imageUrl} />
                            )}
                            {answer.freeText != null && (
                                <Row>
                                    <Col>
                                        <span>"{answer.freeText}"</span>
                                    </Col>
                                </Row>
                            )}
                            {answer.freeImageUrl != null && (
                                <ImageWithButton imageUrl={answer.freeImageUrl} />
                            )}
                        </div>
                    ))}
                    {response.additionalComments != null && (
                        <Row>
                            <Col>
                                <span>"{response.additionalComments}"</span>
                            </Col>
                        </Row>
                    )}
                </Container>
            </ListGroup.Item>
        ));
    };

    return (
        <div className="photos">
            {isFetching ? (
                <Loader />
            ) : (
                <Row>
                    <Col>
                        {data != null &&
                        (data.categorizedResponses.length > 0 ||
                            data.uncategorizedResponses.length > 0) ? (
                            <>
                                <ListGroup>
                                    {renderResponses(data.uncategorizedResponses)}
                                </ListGroup>
                                {showCategorizedResponses ? (
                                    <>
                                        <Row className="align-items-center">
                                            <Col xs={8}>
                                                <span>
                                                    <b>Categorized responses</b>
                                                </span>
                                            </Col>
                                            <Col>
                                                <Button
                                                    variant="link"
                                                    size="sm"
                                                    onClick={() =>
                                                        setShowCategorizedResponses(false)
                                                    }
                                                >
                                                    Hide
                                                </Button>
                                            </Col>
                                        </Row>
                                        <ListGroup>
                                            {renderResponses(data.categorizedResponses)}
                                        </ListGroup>
                                    </>
                                ) : (
                                    <Row>
                                        <Col xs={12}>
                                            <Button
                                                variant="secondary"
                                                size="sm"
                                                onClick={() => setShowCategorizedResponses(true)}
                                                style={{ width: '100%' }}
                                            >
                                                Show categorized
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            </>
                        ) : (
                            <span>
                                No quiz responses for this user.
                                <br />
                                This tab is supported only for users that have taken the new quiz.
                            </span>
                        )}
                    </Col>
                </Row>
            )}
        </div>
    );
};
