import axios from 'axios';
import compress from 'compress-base64';
import { fabric } from 'fabric';
import { FabricJSEditor } from 'fabricjs-react';
import { Cookies } from 'services';
import { sentryException } from 'services/SentryLogging';

import * as canvasTypes from '../../types/canvas';
import * as canvasUtilsTypes from '../../types/canvasUtils';
import Formatter from '../Formatter';
import { getUserToken } from './token-utils';

const imageConfiguration = {
    width: 250,
    type: 'image/png',
    max: 200,
    min: 20,
    quality: 0.4
};

const localStorage = window.localStorage;

const maximumStorageSize = 5000;

const minimumSaveInterval = 1000;

export const initializeCanvas = (
    editor: FabricJSEditor,
    canvasSizesArray: number[],
    canvasName: string,
    type: canvasUtilsTypes.localStorageDataType,
    setToolbarSettings: canvasUtilsTypes.dispatchSetToolbarSettings,
    setCanvasLoad: canvasUtilsTypes.dispatchSetCanvasLoad,
    updateCanvasTotalPrice: canvasUtilsTypes.dispatchUpdateCanvasPrice,
    stylingRoomState: string
) => {
    const editorCanvas = editor.canvas as canvasTypes.ICanvas;
    const canvasSizeIndex = 2;
    editorCanvas.setHeight(canvasSizesArray[canvasSizeIndex]);
    editorCanvas.setWidth(canvasSizesArray[canvasSizeIndex]);
    const restoredCanvas = retrieveCanvasData(canvasName, type);
    if (restoredCanvas) {
        const canvasObject = JSON.parse(restoredCanvas);
        prepareCachecCanvasData(
            updateCanvasTotalPrice,
            canvasSizesArray,
            setCanvasLoad,
            setToolbarSettings,
            editorCanvas,
            canvasName,
            canvasObject.objects
        );

        editorCanvas.boardType = canvasObject?.boardType ?? stylingRoomState;
        editorCanvas.canvasTimestamp = canvasObject?.canvasTimestamp;
    } else {
        editorCanvas.boardType = stylingRoomState;
        saveCanvasToLocalStorage(editorCanvas, canvasName, type);
    }
    return editorCanvas;
};

export const retrieveCanvasData = (
    canvasName: string,
    type: canvasUtilsTypes.localStorageDataType
) => {
    const canvasDataName = `${canvasName}_${type}`;
    const isCanvasExist = localStorage.getItem(canvasDataName) !== null;
    if (isCanvasExist) return localStorage.getItem(canvasDataName);
    else return null;
};

const prepareCachecCanvasData = (
    updateCanvasTotalPrice: canvasUtilsTypes.dispatchUpdateCanvasPrice,
    canvasSizesArray: number[],
    setCanvasLoad: canvasUtilsTypes.dispatchSetCanvasLoad,
    setToolbarSettings: canvasUtilsTypes.dispatchSetToolbarSettings,
    canvas: canvasTypes.ICanvas,
    canvasName: string,
    canvasItems: canvasTypes.ICanvasObject[]
) =>
    canvasItems.map((item) => {
        item.original = item.src;
        if (item.transformations) {
            const transformations = item.transformations;
            const transformationsValues = Object.values(transformations);
            transformationsValues.forEach(async (value) => {
                Object.assign(item, value);
            });
        } else {
            item.transformations = {};
        }
        createImage({
            src: item.src,
            id: item.id,
            base64: false,
            canvas: canvas,
            setCanvasLoad: setCanvasLoad,
            canvasSizesArray: canvasSizesArray,
            canvasName: canvasName,
            setToolbarSettings: setToolbarSettings,
            updateCanvasTotalPrice: updateCanvasTotalPrice,
            canvasItem: item,
            transformationsObject: item.transformations
        });
    });

const saveClonedCanvas = (
    canvas: canvasTypes.ICanvas,
    canvasName: string,
    type: canvasUtilsTypes.localStorageDataType
) => {
    canvas.clone(
        (clonedCanvas: canvasTypes.ICanvas) => {
            (clonedCanvas._objects as canvasTypes.ICanvasObject[]).map((obj) => {
                obj.setSrc(obj.original, () => {
                    localStorage.setItem(
                        `${canvasName}_${type}`,
                        JSON.stringify(
                            clonedCanvas.toJSON([
                                'id',
                                'transformations',
                                'boardType',
                                'canvasTimestamp',
                                'clearBackground'
                            ])
                        )
                    );
                });
            });
        },
        ['original', 'id', 'transformations', 'boardType', 'canvasTimestamp', 'clearBackground']
    );
};

const arrangeItemsByTimestamp = (keys: string[]) =>
    keys.reduce((acc, key) => {
        const entry = localStorage.getItem(key);
        if (entry) {
            try {
                const entryObject = JSON.parse(entry);
                if (entryObject.hasOwnProperty('canvasTimestamp')) {
                    acc.push({ timestamp: entryObject['canvasTimestamp'], key: key });
                }
            } catch (error) {
                const user = Cookies.get('user');
                sentryException(
                    error as Error,
                    'Fail To Arrange Items By Timestamp',
                    `User Id: ${user?.uuid}`
                );
            }
        }
        return acc;
    }, [] as { timestamp: number; key: string }[]);

const saveCanvasToLocalStorage = (
    canvas: canvasTypes.ICanvas,
    canvasName: string,
    type: canvasUtilsTypes.localStorageDataType,
    item?: canvasTypes.ICanvasObject,
    eventType?: string
) => {
    if (
        canvas._objects.length &&
        canvas.canvasTimestamp &&
        (Date.now() - canvas?.canvasTimestamp > minimumSaveInterval || eventType === 'added')
    ) {
        const localStorageSpace = JSON.stringify(localStorage).length / 1000;
        const objectOverallSize = item
            ? JSON.stringify(item.src ?? item._element.src).length / 1000
            : 0;
        let isSufficentSpace = maximumStorageSize - (localStorageSpace + objectOverallSize) > 0;
        if (isSufficentSpace) {
            canvas.canvasTimestamp = Date.now();
            saveClonedCanvas(canvas, canvasName, type);
        } else {
            const keys = Object.keys(localStorage);
            const canvasSetByTimestamp = arrangeItemsByTimestamp(keys);
            const sortedObjects = canvasSetByTimestamp.sort((a, b) => a.timestamp - b.timestamp);
            while (!isSufficentSpace && sortedObjects.length > 0) {
                const canvasToRemove = sortedObjects.shift();
                if (canvasToRemove?.key) {
                    localStorage.removeItem(canvasToRemove.key);
                    localStorage.removeItem(canvasToRemove.key.replace('free', 'freeItems'));
                }
                const localStorageSpace = JSON.stringify(localStorage).length / 1000;
                const objectOverallSize = item ? JSON.stringify(item.src).length / 1000 : 0;
                isSufficentSpace = maximumStorageSize - (localStorageSpace + objectOverallSize) > 0;
                if (isSufficentSpace) {
                    canvas.canvasTimestamp = Date.now();
                    saveClonedCanvas(canvas, canvasName, type);
                }
            }
        }
    } else {
        if (!canvas.canvasTimestamp) canvas.canvasTimestamp = Date.now();
        if (!canvas._objects.length)
            localStorage.setItem(
                `${canvasName}_${type}`,
                JSON.stringify(canvas.toJSON(['boardType', 'canvasTimestamp']))
            );
    }
};

export const convertImageToBase64 = async (
    src: string,
    referer: string = window.location.origin
) => {
    const conf = {
        headers: { token: getUserToken() },
        params: { imageUrl: src, referer }
    };
    const url = `${process.env.REACT_APP_API_GW_PATH}images/encoded`;

    const { data } = await axios.get(url, conf);
    const comprossedData = await compress(data, imageConfiguration);
    if (comprossedData) return comprossedData;
    return data;
};

export const placeImage = (canvas: canvasTypes.ICanvas, imgObject: canvasTypes.ICanvasObject) => {
    const { top, left, angle } = imgObject;
    const bounds = imgObject.getBoundingRect();
    if (angle && left && top && canvas.height && canvas.width) {
        if (angle > 350 || angle < 10) {
            if (top < 0) imgObject.top = 0;
            if (top + bounds.height > canvas.height) imgObject.top = canvas.height - bounds.height;
            if (left < 0) imgObject.left = 0;
            if (left + bounds.width >= canvas.width) imgObject.left = canvas.width - bounds.width;
        }
    }
    return imgObject;
};

export const subscribePreviewEvents = (
    canvas: fabric.Canvas,
    setPreview: canvasUtilsTypes.dispatchSetPreview
) => {
    canvas.on('after:render', (e) => {
        const base64 = (e as any).ctx.canvas.toDataURL();
        if (base64 !== 'data:,') setPreview(base64);
    });
};

export const subscribeCanvasEvents = (
    canvas: canvasTypes.ICanvas,
    canvasName: string,
    setToolbarSettings: canvasUtilsTypes.dispatchSetToolbarSettings
) => {
    canvas.on('object:scaling', (e) => {
        if (e.target && e.target.scaleX && e.target.scaleY) {
            const canvasImageObject = e.target as canvasTypes.ICanvasObject;
            if (!canvasImageObject.transformations) canvasImageObject.transformations = {};
            canvasImageObject.transformations.scaling = {
                scaleX: e.target.scaleX,
                scaleY: e.target.scaleY
            };
            updateToolbar(canvas, canvasImageObject, canvasName, setToolbarSettings, false);
        }
    });
    canvas.on('object:rotating', (e) => {
        if (e.target)
            updateToolbar(
                canvas,
                e.target as canvasTypes.ICanvasObject,
                canvasName,
                setToolbarSettings,
                false
            );
    });
    canvas.on('object:moving', () => {
        setToolbarSettings({
            show: false
        });
    });
    canvas.on('object:moved', (e: any) => {
        if ((e.target as canvasTypes.ICanvasObject) && e.target.left && e.target.top) {
            const { top, left, angle } = e.target;
            const bounds = e.target.getBoundingRect();
            if (angle > 350 || angle < 10) {
                if (top < 0) e.target.top = 0;
                if (top + bounds.height > canvas.getHeight())
                    e.target.top = canvas.getHeight() - bounds.height;
                if (left < 0) e.target.left = 0;
                if (left + bounds.width >= canvas.getWidth())
                    e.target.left = canvas.getWidth() - bounds.width;
            }
            const canvasImageObject = e.target as canvasTypes.ICanvasObject;
            if (!canvasImageObject.transformations) canvasImageObject.transformations = {};
            canvasImageObject.transformations.moving = { left: e.target.left, top: e.target.top };
            updateToolbar(
                canvas,
                placeImage(canvas, e.target),
                canvasName,
                setToolbarSettings,
                false
            );
        }
    });
    canvas.selection = false;
    canvas.preserveObjectStacking = true;
};

export const updateToolbar = (
    canvas: canvasTypes.ICanvas,
    item: canvasTypes.ICanvasObject,
    canvasName: string,
    setToolbarSettings: canvasUtilsTypes.dispatchSetToolbarSettings,
    disabled = false
) => {
    if (canvas) {
        saveCanvasToLocalStorage(canvas, canvasName, 'free', item, 'canvasAction');
    }
    item.hasControls = true;
    const bounds = item.getBoundingRect();

    setToolbarSettings({
        item: item,
        show: true,
        disabled,
        x: bounds.left + (bounds.width / 2 - 230 / 2),
        y: bounds.top - 60
    });
    canvas.renderAll();
};

const removeTransformer = (
    canvas: canvasTypes.ICanvas,
    setToolbar: canvasUtilsTypes.dispatchSetToolbarSettings
) => {
    if (canvas) {
        const active = canvas.getActiveObject();
        if (active) {
            active.hasControls = false;
            active.hasBorders = false;
            canvas.renderAll();
        }
    }
    setToolbar({ show: false });
};

export const setCanvasDimensions = (
    canvasSizesArray: number[],
    canvasSizeIndex: number,
    canvas: canvasTypes.ICanvas | undefined,
    dimension: number,
    setToolbarSettings: canvasUtilsTypes.dispatchSetToolbarSettings,
    setDimension: canvasUtilsTypes.disptachSetDimension
) => {
    const size = canvasSizesArray[canvasSizeIndex];
    const maxDimension = window.innerHeight - 150;
    const _dimension = size < maxDimension ? size : maxDimension;
    if (canvas && dimension !== _dimension) {
        removeTransformer(canvas, setToolbarSettings);
        canvas.setDimensions(
            {
                width: _dimension,
                height: _dimension
            },
            { cssOnly: canvasSizeIndex < 2 }
        );
    }
    const dimensionChange = size < maxDimension ? size : maxDimension;
    setDimension(dimensionChange);
};

const removeBackground = async (src: string, canvasItem: canvasTypes.ICanvasObject) => {
    const image = new Image();
    image.src = src;
    const backgroundSrc = await replaceColor({ img: image, alpha: 0 });
    if (backgroundSrc)
        canvasItem.setSrc(backgroundSrc, () => {
            canvasItem.src = backgroundSrc;
        });
};

const updateCanvasItemProperties = (
    canvasItem: canvasTypes.ICanvasObject,
    transformationsObject: canvasTypes.transformationsObject,
    transformationsValues: any[]
) =>
    transformationsValues.map(async (value) => {
        if (
            canvasItem &&
            value !== transformationsObject.crop &&
            value !== transformationsObject.isClearBackground
        ) {
            Object.assign(canvasItem, value);
        }
    });

const procesImageOnCanvas = async (
    transformationsObject: canvasTypes.transformationsObject,
    canvasItem: canvasTypes.ICanvasObject,
    cachedCanvasItem: canvasTypes.ICanvasObject,
    img: HTMLImageElement
) => {
    canvasItem.setSrc(cachedCanvasItem.src);
    canvasItem.transformations = cachedCanvasItem.transformations;
    const transformationsKeys = Object.keys(transformationsObject);
    const transformationsValues = Object.values(transformationsObject);
    const hasCropTransformation = transformationsKeys.includes('crop');
    const hasBackgroundTransformation = transformationsKeys.includes('isClearBackground');
    if (hasCropTransformation || hasBackgroundTransformation) {
        if (hasCropTransformation) {
            const cropSrc = canvasItem._element.src;
            if (cropSrc) {
                if (hasBackgroundTransformation) {
                    canvasItem.cropped = cropSrc;
                    removeBackground(canvasItem.cropped ?? img.src, canvasItem);
                } else {
                    canvasItem.setSrc(cropSrc, () => {
                        canvasItem.src = cropSrc;
                        canvasItem.cropX = transformationsObject.crop?.x;
                        canvasItem.cropY = transformationsObject.crop?.y;
                        canvasItem.width = transformationsObject.crop?.width;
                        canvasItem.height = transformationsObject.crop?.height;
                    });
                }
            }
        } else {
            removeBackground(img.src, canvasItem);
        }
        updateCanvasItemProperties(canvasItem, transformationsObject, transformationsValues);
    } else {
        updateCanvasItemProperties(canvasItem, transformationsObject, transformationsValues);
    }
    return Promise.resolve(canvasItem);
};

export const createImage = async ({
    src,
    base64 = false,
    id,
    setCanvasLoad,
    canvas,
    canvasSizesArray,
    canvasName,
    setToolbarSettings,
    updateCanvasTotalPrice,
    canvasItem,
    transformationsObject
}: {
    src: string;
    base64: boolean;
    id: string;
    setCanvasLoad: canvasUtilsTypes.dispatchSetCanvasLoad;
    canvas: canvasTypes.ICanvas;
    canvasSizesArray: number[];
    canvasName: string;
    setToolbarSettings: canvasUtilsTypes.dispatchSetToolbarSettings;
    updateCanvasTotalPrice: canvasUtilsTypes.dispatchUpdateCanvasPrice;
    canvasItem?: canvasTypes.ICanvasObject;
    transformationsObject?: canvasTypes.transformationsObject;
}) => {
    try {
        setCanvasLoad(true);
        const img = new Image() as canvasTypes.ICanvasImage;
        if (base64) {
            const imageData = await convertImageToBase64(src);
            img.src = imageData;
        } else {
            img.src = src;
        }
        img.id = id;
        img.clearBackground = true;
        img.cropped = false;
        img.onload = () => {
            const canvasSizeIndex = 2;
            fabric.Image.fromURL(img.src, async (fImage) => {
                if (fImage.width && fImage.width > canvasSizesArray[canvasSizeIndex] / 4)
                    fImage.scaleToWidth(canvasSizesArray[canvasSizeIndex] / 4);
                fImage
                    .set({
                        lockUniScaling: true,
                        cornerSize: 8,
                        borderColor: '#dbdbdb',
                        cornerColor: '#dbdbdb',
                        cornerStrokeColor: '#dbdbdb'
                    })
                    .on('selected', (e) => {
                        if (e.target)
                            updateToolbar(
                                canvas,
                                e.target as canvasTypes.ICanvasObject,
                                canvasName,
                                setToolbarSettings
                            );
                    })
                    .on('moved', (e) => {
                        if (e.target) {
                            updateToolbar(
                                canvas,
                                e.target as canvasTypes.ICanvasObject,
                                canvasName,
                                setToolbarSettings
                            );
                        }
                    })
                    .on('modified', (e: any) => {
                        if (e.action !== 'drag')
                            updateToolbar(
                                canvas,
                                e.target as canvasTypes.ICanvasObject,
                                canvasName,
                                setToolbarSettings
                            );
                    })
                    .on('deselected', (e) => {
                        if (e.target) {
                            removeTransformer(canvas, setToolbarSettings);
                        }
                    })
                    .on('added', () => {
                        setCanvasLoad(false);
                        updateCanvasTotalPrice();
                        saveCanvasToLocalStorage(
                            canvas,
                            canvasName,
                            'free',
                            fImage as canvasTypes.ICanvasObject,
                            'added'
                        );
                    });

                fImage.controls.mtr.offsetY = -20;
                if (canvas && !transformationsObject) {
                    const canvasImageObject = fImage as canvasTypes.ICanvasObject;
                    canvasImageObject.original = img.src;
                    canvasImageObject.src = img.src;
                    canvasImageObject.id = img.id;
                    canvasImageObject.transformations = {};
                    canvas.add(canvasImageObject);
                    canvas.renderAll();
                } else if (canvas && canvasItem && transformationsObject) {
                    const canvasImageObject = fImage as canvasTypes.ICanvasObject;
                    canvasImageObject.id = canvasItem.id;
                    canvasImageObject.original = canvasItem.original;
                    const processedCanvasItem = await procesImageOnCanvas(
                        transformationsObject,
                        canvasImageObject,
                        canvasItem,
                        img
                    );
                    if (processedCanvasItem) {
                        canvas.add(processedCanvasItem);
                        canvas.renderAll();
                    }
                }
            });
        };
    } catch (e) {
        setCanvasLoad(false);
    }
};

export const updateCanvasItems = (
    canvas: canvasTypes.ICanvas,
    setToolbarSettings: canvasUtilsTypes.dispatchSetToolbarSettings,
    items: canvasTypes.ICanvasItem[],
    ps: canvasTypes.ICanvasItem[],
    canvasItems: string[],
    setCanvasItems: canvasUtilsTypes.dispatchSetCanvasItems,
    setCounter: canvasUtilsTypes.dispatchSetCounter,
    setCanvasLoad: canvasUtilsTypes.dispatchSetCanvasLoad,
    canvasName: string,
    canvasSizesArray: number[],
    updateCanvasTotalPrice: canvasUtilsTypes.dispatchUpdateCanvasPrice,
    type: canvasUtilsTypes.localStorageDataType
) => {
    if (items.length > 0) {
        localStorage.setItem(`${canvasName}_${type}`, JSON.stringify(items));

        removeTransformer(canvas, setToolbarSettings);
        const filtered = items.filter((item) => !canvasItems.includes(item.id));
        if (filtered && canvasItems) {
            filtered.forEach((item) => {
                if (item?.id && item.picture)
                    createImage({
                        src: item.picture,
                        id: item.id,
                        base64: true,
                        canvas: canvas,
                        setCanvasLoad: setCanvasLoad,
                        canvasSizesArray: canvasSizesArray,
                        canvasName: canvasName,
                        setToolbarSettings: setToolbarSettings,
                        updateCanvasTotalPrice: updateCanvasTotalPrice
                    });
            });
        }

        const itemsIdsFromList = filtered.reduce((acc, item) => {
            acc.push(item.id);
            return acc;
        }, [] as string[]);

        setCanvasItems([...canvasItems, ...itemsIdsFromList]);

        setCounter({
            items: items.length,
            ps: ps.length
        });
    }
};

export const onCropDone = (
    cropped: string,
    canvas: canvasTypes.ICanvas,
    canvasName: string,
    setToolbarSettings: canvasUtilsTypes.dispatchSetToolbarSettings,
    setCrop: canvasUtilsTypes.dispatchSetCropModal
) => {
    const item = canvas.getActiveObject() as canvasTypes.ICanvasObject;
    setCrop({ show: false });
    item.cropped = cropped;
    if (item?.transformations.isClearBackground) {
        item.clearBackground = false;
        delete item?.transformations['isClearBackground'];
    }

    item.setSrc(cropped, () => {
        canvas.renderAll();
        updateToolbar(canvas, item, canvasName, setToolbarSettings, false);
    });
};

export const onToolbarAction = async (
    action: string,
    canvas: canvasTypes.ICanvas,
    setCanvasItems: canvasUtilsTypes.dispatchSetCanvasItems,
    canvasItems: string[],
    items: canvasTypes.ICanvasItem[],
    canvasName: string,
    setToolBarSettings: canvasUtilsTypes.dispatchSetToolbarSettings,
    removeFromCanvas: (id: string) => void,
    setCrop: canvasUtilsTypes.dispatchSetCropModal,
    updateCanvasTotalPrice: canvasUtilsTypes.dispatchUpdateCanvasPrice
) => {
    if (canvas) {
        const activeItem = canvas.getActiveObject() as canvasTypes.ICanvasObject;
        if (activeItem && !activeItem.transformations) activeItem.transformations = {};
        switch (action) {
            case 'remove':
                setCanvasItems(canvasItems.filter((item) => activeItem && item !== activeItem.id));
                removeFromCanvas(activeItem.id);
                canvas.remove(activeItem);
                removeTransformer(canvas, setToolBarSettings);
                updateCanvasTotalPrice();
                saveCanvasToLocalStorage(canvas, canvasName, 'free', undefined, 'remove');
                if (items.length == 1)
                    localStorage.setItem(`${canvasName}_${'freeItems'}`, JSON.stringify({}));
                break;
            case 'flip':
                const flipX = activeItem.get('flipX');
                activeItem.set('flipX', !flipX);
                activeItem.transformations.flipX = { flipX: flipX };
                updateToolbar(canvas, activeItem, canvasName, setToolBarSettings, false);
                break;
            case 'flop':
                const flipY = activeItem.get('flipY');
                activeItem.set('flipY', !flipY);
                activeItem.transformations.flipY = { flipY: !flipY };
                updateToolbar(canvas, activeItem, canvasName, setToolBarSettings, false);
                break;
            case 'backward':
                canvas.sendBackwards(activeItem);
                updateToolbar(canvas, activeItem, canvasName, setToolBarSettings, false);
                break;
            case 'forward':
                canvas.bringToFront(activeItem);
                updateToolbar(canvas, activeItem, canvasName, setToolBarSettings, false);
                break;
            case 'remove-background':
            case 'undo-remove-background':
                const img = new Image();
                img.src = activeItem.cropped
                    ? activeItem.cropped
                    : activeItem.original ?? activeItem.src;
                const src = await replaceColor(
                    !activeItem.clearBackground
                        ? { img, alpha: 0 }
                        : { img, color: { r: 0, g: 0, b: 0 }, alpha: 255 }
                );
                if (src)
                    activeItem.setSrc(src, () => {
                        canvas.renderAll();
                        activeItem.transformations.isClearBackground = {
                            clearBackground: action === 'remove-background'
                        };
                        updateToolbar(canvas, activeItem, canvasName, setToolBarSettings, false);
                    });
                activeItem.clearBackground = action === 'remove-background';

                break;
            case 'crop':
                if (!activeItem.cropped || activeItem.cropped === 'false') {
                    setCrop({
                        show: true,
                        item: activeItem.original ?? activeItem._element.src,
                        index: getItemIndex(activeItem.id, items),
                        canvasItem: activeItem
                    });
                } else {
                    activeItem.cropped = 'false';
                    setCrop({ show: false });
                }
                break;
        }
        canvas.renderAll();
    }
};

export const replaceColor = async ({
    img,
    color = { r: 255, g: 255, b: 255 },
    replace = { r: 255, g: 255, b: 255 },
    alpha = 0
}: {
    img: HTMLImageElement;
    color?: { [key: string]: number };
    replace?: { [key: string]: number };
    alpha?: number;
}) => {
    try {
        const c = document.createElement('canvas');
        const w = img.width,
            h = img.height;
        c.width = w;
        c.height = h;
        const ctx = c.getContext('2d');
        if (ctx && w > 0 && h > 0) {
            ctx.drawImage(img, 0, 0, w, h);
            const imageData = ctx.getImageData(0, 0, w, h);
            const pixel = imageData.data;

            const r = 0,
                g = 1,
                b = 2,
                a = 3;
            for (let p = 0; p < pixel.length; p += 4) {
                if (
                    pixel[p + r] === color.r &&
                    pixel[p + g] === color.g &&
                    pixel[p + b] === color.b &&
                    pixel[p + a] !== alpha
                ) {
                    pixel[p + r] = replace.r;
                    pixel[p + g] = replace.g;
                    pixel[p + b] = replace.b;
                    pixel[p + a] = alpha;
                }
            }

            ctx.putImageData(imageData, 0, 0);
            const src = c.toDataURL('image/png');
            return Promise.resolve(src);
        }
    } catch (e) {
        return Promise.reject(e);
    }
};

export const getItemIndex = (id: string, items: canvasTypes.ICanvasItem[]) =>
    items.findIndex((item) => item && item.id === id);

export const toggleSize = (
    canvas: canvasTypes.ICanvas,
    canvasSizeIndex: number,
    setCanvasSizeIndex: (size: number) => void,
    setRoomState: canvasUtilsTypes.dispatchSetRoomState,
    canvasRoomState: string
) => {
    setRoomState(canvasRoomState);
    setCanvasSizeIndex(canvasSizeIndex === 1 ? 2 : 1);
    canvas.boardType = canvasRoomState;
};

export const closeCanvas = (setCanvasSizeIndex: (size: number) => void) => setCanvasSizeIndex(0);

export const openCanvas = (
    setCanvasSizeIndex: (size: number) => void,
    setCanvasLayout: (layout: string) => void,
    layout: string
) => {
    setCanvasSizeIndex(1);
    setCanvasLayout(layout);
};

export const checkDisableSave = (
    canvasLayout: string,
    items: canvasTypes.ICanvasItem[],
    ps: canvasTypes.ICanvasItem[]
) => {
    const _items = canvasLayout === 'free' ? items : ps;
    return !_items.length || _items.filter((item) => !!item).length < 3;
};

export const onCanvasSave = (
    canvasLayout: string,
    onSave: (canvasLayout: string | null) => void,
    canvas: canvasTypes.ICanvas,
    setToolBarSettings: canvasUtilsTypes.dispatchSetToolbarSettings
) => {
    if (canvasLayout === 'free') removeTransformer(canvas, setToolBarSettings);
    if (onSave) onSave(canvasLayout === 'free' && canvas ? canvas.toDataURL() : null);
};

export const getTotal = (items: canvasTypes.ICanvasItem[]) => {
    let total = 0;
    items.forEach((item) => {
        item && typeof item.price === 'number'
            ? (total += item.price)
            : item && typeof item.price === 'string'
            ? (total += parseFloat(item.price))
            : (total += 0);
    });
    return Formatter.price(total);
};
