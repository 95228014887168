/* eslint-disable no-useless-escape */
import axios from 'axios';
import moment from 'moment';

const Formatter = {
    price: (price, fractionDigits = 0, currency = 'USD') => {
        let options = {
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits
        };
        if (currency) {
            options.style = 'currency';
            options.currency = currency;
        }

        const formatter = new Intl.NumberFormat('en-US', options);
        if (typeof price === 'string') price = price.replace(',', '');
        if (!price) price = 0;

        const formatted = parseInt(price, 10);

        return formatter.format(formatted);
    },
    name: (name) =>
        name
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' '),
    queryString: (params) =>
        params
            .substring(1)
            .split('&')
            .reduce((ac, a) => ({ ...ac, [a.split('=')[0]]: a.split('=')[1] }), {}),
    time: (timestamp, format = 'MMMM DD, YYYY') => {
        let date = moment(new Date(timestamp));
        if (!date.isValid()) date = moment.unix(timestamp);
        let formatted = date.fromNow();

        if (moment().diff(date, 'days') > 7) {
            formatted = date.format(format);
        }
        return formatted;
    },
    date: (timestamp) => {
        let date = moment(new Date(timestamp));
        if (!date.isValid()) date = moment.unix(timestamp);
        let formatted = date.format('MMM DD');

        const currentYear = new Date().getFullYear();

        if (currentYear > date.year()) {
            formatted = date.format('MMM DD, YYYY');
        }
        return formatted;
    },
    base64: (url) => axios.post('https://app.api.wishi.me/image/base64', { img: url }),
    number: (number) => new Intl.NumberFormat().format(number),
    priceFormatter: (price) => {
        if (typeof price !== 'string') price = JSON.stringify(price);
        return price ? JSON.stringify(parseInt(price.replace(',', ''), 10)) : price;
    }
};

export default Formatter;
