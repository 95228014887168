import './style.scss';

import { useEffect, useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { GridItem, Loader } from '../../../components';
import { profile as texts } from '../../../content';
import Empty from './Empty';

const Collections = ({
    loading,
    user,
    closetTags = [],
    closet = [],
    gridSize,
    loadClosetTags,
    loadCloset
}) => {
    const history = useHistory();
    const [gridItems, setGridItems] = useState();
    const [selected, setSelected] = useState();

    useEffect(() => {
        if (user) {
            selected ? loadCloset({ tags: selected.uuid }) : fetchData();
        }
    }, [user, selected]);

    useEffect(() => {
        const items = selected && closet ? closet : closetTags;
        if (items.length) setGridItems(items);
    }, [closetTags, closet]);

    const fetchData = () => loadClosetTags({ from: closetTags ? closetTags.length : 0 });

    const onItemClick = (item) => {
        if (selected) {
            if (item.catalog_item_id) history.push(`/item/${item.catalog_item_id}`);
        } else {
            setSelected(item);
        }
    };

    return (
        <Container className="profile-collections" fluid>
            {loading && <Loader />}
            <Row>
                <Col id="results" className="results">
                    {selected && (
                        <div className="selected-tag">
                            <div>
                                <label>{selected.name}</label>
                                <div className="close" onClick={() => setSelected()} />
                            </div>
                            <h6>
                                {texts[selected.items_count > 1 ? 'items' : 'item'].replace(
                                    '%count%',
                                    selected.items_count
                                )}
                            </h6>
                        </div>
                    )}
                    {gridItems && gridItems.length ? (
                        gridItems.map((item, index) => {
                            let Item = selected && closet ? GridItem : Collection;
                            return (
                                <Item
                                    key={index}
                                    item={item}
                                    type="closet"
                                    size={gridSize}
                                    onClick={() => onItemClick(item)}
                                />
                            );
                        })
                    ) : (
                        <Empty section="collections" />
                    )}
                </Col>
            </Row>
        </Container>
    );
};

const Collection = ({ item, size, onClick }) => (
    <div className={`collection ${size}`} onClick={onClick}>
        <div className="items">
            <Image className="collection-image" src={item.cover_photo} />
            {item.items && (
                <div className="collection-items">
                    {item.items.slice(0, 4).map(({ picture, index }) => (
                        <Image className="collection-item" src={picture} key={index} />
                    ))}
                    {item.items.length > 4 && (
                        <p className="collection-item">+{item.items.length - 4}</p>
                    )}
                </div>
            )}
        </div>
        <label>{item.name}</label>
        <h6>
            {texts[item.items_count > 1 ? 'items' : 'item'].replace('%count%', item.items_count)}
        </h6>
    </div>
);

export default Collections;
