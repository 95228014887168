import './style.scss';

import {
    faFacebookF,
    faInstagram,
    faLinkedin,
    faPinterestP
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { ProfilePhoto } from '../../components';
import { styling } from '../../content';
import { useUserName } from '../../hooks';

const Header = ({
    client,
    styleData = {},
    dressingRoom = false,
    onDetailsToggle,
    onMenuSelect,
    active,
    onBack
}) => {
    const { fullname } = useUserName(client);
    const getActiveState = (tab) => (tab === active ? 'active' : '');

    const onMenuClick = (section) => {
        if (active !== section) onMenuSelect(section);
    };

    return (
        <Row className="header">
            <div className="back-btn" onClick={onBack} />
            {client && (
                <Col className="client">
                    <ProfilePhoto user={client} level={true} />
                    <div className="client-name">
                        <div className="name">{fullname}</div>
                        <div className="social-icons">
                            {styleData?.instagram_username && (
                                <a
                                    href={`http://instagram.com/${styleData.instagram_username}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FontAwesomeIcon icon={faInstagram} />
                                </a>
                            )}
                            {styleData.facebook_username && (
                                <a
                                    href={`http://facebook.com/${styleData.facebook_username}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FontAwesomeIcon icon={faFacebookF} />
                                </a>
                            )}
                            {styleData.linkedin_username && (
                                <a
                                    href={`http://linkedin.com/${styleData.linkedin_username}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FontAwesomeIcon icon={faLinkedin} />
                                </a>
                            )}
                            {styleData.pinterest_username && (
                                <a
                                    href={`http://pinterest.com/${styleData.pinterest_username}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FontAwesomeIcon icon={faPinterestP} />
                                </a>
                            )}
                        </div>
                    </div>
                </Col>
            )}
            <Col className="menu">
                {client && (
                    <p onClick={() => onMenuSelect('closet')} className={getActiveState('closet')}>
                        {dressingRoom
                            ? styling.header.mycloset
                            : styling.header.closet.replace('%client%', client.first_name)}
                    </p>
                )}
                <p onClick={() => onMenuClick('shop')} className={getActiveState('shop')}>
                    {styling.header.shop}
                </p>
                <p onClick={() => onMenuClick('store')} className={getActiveState('store')}>
                    {styling.header.store}
                </p>
                <p
                    onClick={() => onMenuClick('inspiration')}
                    className={getActiveState('inspiration')}
                >
                    {styling.header.inspiration}
                </p>
                <p onClick={() => onMenuClick('boards')} className={getActiveState('boards')}>
                    {styling.header.boards}
                </p>
            </Col>
            {!dressingRoom && (
                <Col sm={1}>
                    <p className="details-toggle" onClick={() => onDetailsToggle(true)}>
                        {styling.header.details}
                    </p>
                </Col>
            )}
        </Row>
    );
};

export default Header;
