import { sentryException } from 'services/SentryLogging';

import { Config, Cookies, Payments, Tracking } from '../../../services';

export const PAYMENT_START = 'PAYMENT_START';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_FAILED = 'PAYMENT_FAILED';
export const CLEAR_RESULT = 'CLEAR_RESULT';

export const UPDATE_PAYMENT_TOKEN = 'UPDATE_PAYMENT_TOKEN';
export const PAYMENT_BUTTON_DISABLED = 'PAYMENT_BUTTON_DISABLED';

export const LOAD_COUNTRIES_SUCCESS = 'LOAD_COUNTRIES_SUCCESS';
export const LOAD_COUNTRIES_FAILED = 'LOAD_COUNTRIES_FAILED';
export const LOAD_STATES_SUCCESS = 'LOAD_STATES_SUCCESS';
export const LOAD_STATES_FAILED = 'LOAD_STATES_FAILED';
export const UPDATE_PAYMENT_HISOTRY = 'UPDATE_PAYMENT_HISOTRY';

export const paymentStart = () => ({
    type: PAYMENT_START
});

export const paymentSuccess = (result) => ({
    type: PAYMENT_SUCCESS,
    payload: { result }
});

export const paymentFailed = (error) => ({
    type: PAYMENT_FAILED,
    payload: { error }
});

export const clearResult = () => ({
    type: CLEAR_RESULT
});

export const updatePaymentToken = (token) => ({
    type: UPDATE_PAYMENT_TOKEN,
    payload: { token }
});

export const paymentButtonDisabled = (buttonDisabled) => ({
    type: PAYMENT_BUTTON_DISABLED,
    payload: { buttonDisabled }
});

export const loadCountriesSuccess = (countries) => ({
    type: LOAD_COUNTRIES_SUCCESS,
    payload: { countries }
});

export const loadCountriesFailed = (error) => ({
    type: LOAD_COUNTRIES_FAILED,
    payload: { error }
});

export const loadStatesSuccess = (states) => ({
    type: LOAD_STATES_SUCCESS,
    payload: { states }
});

export const loadStatesFailed = (error) => ({
    type: LOAD_STATES_FAILED,
    payload: { error }
});

export const pay = () => {
    return async (dispatch, getState) => {
        dispatch(paymentStart());
        try {
            const { token, uuid } = Cookies.get('user');
            const { stylist, plan, goal } = getState().booking;
            let { model, price, coupon, paymentToken } = getState().payments;

            let data = {
                subtotal: price,
                customer_uuid: uuid,
                payment_token: paymentToken,
                coupon_code: coupon ? coupon.code : '',
                plan: plan.value,
                stylist_uuid: stylist.uuid,
                platform: Config.isMobile() ? 'mobile-web' : 'desktop-web'
            };
            if (goal.value === 'closet_cleanout') data.goal = 'GOAL_CLOSET_CLEANOUT';
            if (goal.value === 'close') model = 'onetime';

            const result = await Payments.payInvoice(data, model, token);
            const paymentData = result.data.data;

            Tracking.google({
                type: 'event',
                event: 'purchase',
                data: {
                    transaction_id: paymentData.id,
                    value: paymentData.total,
                    currency: 'USD'
                }
            });
            Tracking.facebook('track', 'Purchase', {
                device: Config.isMobile() ? 'mobile' : 'desktop',
                content_category: paymentData.plan_name,
                content_type: 'product',
                content_ids: [paymentData.stylist_uuid],
                currency: 'USD',
                num_items: 1,
                value: paymentData.total
            });
            Tracking.tag({
                event: 'eec.purchase',
                ecommerce: {
                    currencyCode: 'USD',
                    purchase: {
                        actionField: {
                            id: paymentData.id,
                            revenue: paymentData.total,
                            coupon: coupon ? coupon.code : ''
                        },
                        products: [
                            {
                                id: stylist.uuid,
                                name: stylist.name,
                                category: '/category/stylist/',
                                variant: goal.value,
                                price: paymentData.total,
                                quantity: 1,
                                dimension3: 'Ecommerce'
                            }
                        ]
                    }
                }
            });
            try {
                await Tracking.report(
                    {
                        event_type: 'clicked_pay_CTA',
                        uuid,
                        stylist_uuid: stylist.uuid
                    },
                    token
                );
            } catch (e) {
                sentryException(e, 'Cannot track CTA pay click');
            }
            dispatch(paymentSuccess(paymentData));
        } catch (e) {
            sentryException(e, "Couldn't process payment request");
            dispatch(paymentFailed(e));
        }
    };
};

export const getCountries = () => {
    return async (dispatch) => {
        try {
            const { token } = Cookies.get('user');
            const { data } = await Payments.getCountries(token);
            dispatch(loadCountriesSuccess(data));
        } catch (error) {
            dispatch(loadCountriesFailed(error));
        }
    };
};

export const getStates = (country) => {
    return async (dispatch) => {
        try {
            const { token } = Cookies.get('user');
            const { data } = await Payments.getStates(country, token);
            dispatch(loadStatesSuccess(data));
        } catch (error) {
            dispatch(loadStatesFailed(error));
        }
    };
};
